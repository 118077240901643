import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { SidebarInput } from 'components/sidebar-input';
import { AddressSubmit } from 'components/address-input/types';
import { config } from 'config';
import { maxLengthValidation } from 'utils/form-validation';

type AddressInputIntlProps = {
  name: 'residentialAddress' | 'shippingAddress';
  registerField: UseFormMethods['register'];
  errors: DeepMap<
    AddressSubmit['residentialAddress'] | AddressSubmit['shippingAddress'],
    FieldError
  >;
};

export const AddressInputIntl = ({
  name,
  registerField,
  errors,
}: AddressInputIntlProps): React.ReactElement => {
  const { country } = config;

  switch (country) {
    case 'Japan':
      return (
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Postcode"
              name={`${name}.postalCode`}
              errorMessage={errors?.postalCode?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Prefecture"
              name={`${name}.prefecture`}
              errorMessage={errors?.prefecture?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Municipality"
              name={`${name}.municipality`}
              errorMessage={errors?.municipality?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Town Area"
              name={`${name}.townArea`}
              errorMessage={errors?.townArea?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Country"
              name={`${name}.country`}
              errorMessage={errors?.country?.message}
            />
          </div>
        </div>
      );
    case 'United Kingdom':
      return (
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Company"
              name={`${name}.company`}
              errorMessage={errors?.company?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Building"
              name={`${name}.building`}
              errorMessage={errors?.building?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Line 1"
              name={`${name}.line1`}
              errorMessage={errors?.line1?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Line 2"
              name={`${name}.line2`}
              errorMessage={errors?.line2?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="City"
              name={`${name}.city`}
              errorMessage={errors?.city?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Postcode"
              name={`${name}.postalCode`}
              errorMessage={errors?.postalCode?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="County"
              name={`${name}.state`}
              errorMessage={errors?.state?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Country"
              name={`${name}.country`}
              errorMessage={errors?.country?.message}
            />
          </div>
        </div>
      );
    case 'Australia': {
      return (
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Company"
              name={`${name}.company`}
              errorMessage={errors?.company?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Building"
              name={`${name}.building`}
              errorMessage={errors?.building?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Line 1"
              name={`${name}.line1`}
              errorMessage={errors?.line1?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Line 2"
              name={`${name}.line2`}
              errorMessage={errors?.line2?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Suburb"
              name={`${name}.city`}
              errorMessage={errors?.city?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Postcode"
              name={`${name}.postalCode`}
              errorMessage={errors?.postalCode?.message}
            />
          </div>
          <div className="flex items-center">
            <div className="w-1/3">
              <label
                className="block text-gray-500 font-bold text-left pr-4"
                htmlFor="state"
              >
                State
              </label>
            </div>
            <div className="w-2/3">
              <select
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id={`${name}.state`}
                ref={registerField}
                name={`${name}.state`}
              >
                <option value="">Unknown</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NSW">New South Wales</option>
                <option value="NT">Northern Territory</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="VIC">Victoria</option>
                <option value="WA">Western Australia</option>
              </select>
            </div>
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Country"
              name={`${name}.country`}
              errorMessage={errors?.country?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField(
                maxLengthValidation('Delivery Instructions', 80),
              )}
              label="Delivery Instructions"
              name={`${name}.deliveryInstructions`}
              errorMessage={errors?.deliveryInstructions?.message}
            />
          </div>
        </div>
      );
    }
    default:
      return (
        <div className="flex flex-col gap-2">
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Company"
              name={`${name}.company`}
              errorMessage={errors?.company?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Building"
              name={`${name}.building`}
              errorMessage={errors?.building?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Line 1"
              name={`${name}.line1`}
              errorMessage={errors?.line1?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Line 2"
              name={`${name}.line2`}
              errorMessage={errors?.line2?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="City"
              name={`${name}.city`}
              errorMessage={errors?.city?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Postcode"
              name={`${name}.postalCode`}
              errorMessage={errors?.postalCode?.message}
            />
          </div>
          <div className="flex items-center">
            <SidebarInput
              ref={registerField}
              label="Country"
              name={`${name}.country`}
              errorMessage={errors?.country?.message}
            />
          </div>
        </div>
      );
  }
};

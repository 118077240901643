import { gql, useMutation, useQuery } from '@apollo/client';
import { Dropdown } from 'components/dropdown';
import { Input } from 'components/input';
import { Loading } from 'components/loading';
import { formatDate, formatDateAndTime } from 'utils/misc';
import { ReactComponent as CloseIcon } from 'components/assets/close.svg';
import {
  CreateCouponCampaignCouponMutation,
  CreateCouponCampaignCouponMutationVariables,
  GetCouponCampaignQuery,
  GetCouponCampaignQueryVariables,
  UpdateCouponCampaignCouponMutation,
  UpdateCouponCampaignCouponMutationVariables,
  UpdateCouponCampaignMutation,
  UpdateCouponCampaignMutationVariables,
} from 'graphql/types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TextArea } from 'components/text-area';
import { useState } from 'react';
import { Modal } from 'components/modal';
import { requiredValidation } from 'utils/form-validation';
import { Button } from 'components/button';
import { GoPencil } from 'react-icons/go';
import { FaPlus } from 'react-icons/fa';
import clsx from 'clsx';

type CouponCampaign = NonNullable<GetCouponCampaignQuery['couponCampaign']>;

export const CouponCampaign = (): React.ReactElement => {
  const { couponCampaignId } = useParams<{ couponCampaignId: string }>();

  const { data, loading } = useQuery<
    GetCouponCampaignQuery,
    GetCouponCampaignQueryVariables
  >(
    gql`
      query GetCouponCampaign($id: ID!) {
        couponCampaign(id: $id) {
          id
          displayName
          description
          status
          stage
          discountStrategy
          externalCampaignUrl
          availableStatusTransitions
          discountAmount {
            id
            ... on CouponCampaignFixedDiscountAmount {
              monetaryValue: value {
                id
                formattedAmount
              }
            }
            ... on CouponCampaignPercentageDiscountAmount {
              value
            }
          }
          startTime
          expiryTime
          coupons {
            id
            code
            exhausted
            limit
            perCustomerLimit
            totalRedemptions
          }
          targetedVariants {
            id
            inventory {
              id
              sku
            }
          }
          parameters {
            id
            displayName
            description
            ... on CouponCampaignBooleanTemplateParameter {
              boolValue: value
            }
            ... on CouponCampaignStringTemplateParameter {
              stringValue: value
            }
            ... on CouponCampaignPercentageTemplateParameter {
              numberValue: value
            }
            ... on CouponCampaignNumberTemplateParameter {
              numberValue: value
            }
            ... on CouponCampaignTimestampTemplateParameter {
              timestampValue: value
            }
            ... on CouponCampaignStringArrayTemplateParameter {
              value
            }
            ... on CouponCampaignSkuListTemplateParameter {
              value
            }
          }
          template {
            id
          }
        }
      }
    `,
    {
      variables: {
        id: couponCampaignId,
      },
    },
  );

  const campaign = data?.couponCampaign;

  const [couponEditingInfo, setCouponEditingInfo] = useState<
    | {
        existing?: CouponCampaign['coupons'][number];
      }
    | undefined
  >();
  const closeModal = () => setCouponEditingInfo(undefined);

  if (loading) {
    return <Loading />;
  }

  if (!campaign) {
    return (
      <div>
        No coupon campaign with the id: &quot;{couponCampaignId}&quot; was
        found.
      </div>
    );
  }

  return (
    <div>
      {couponEditingInfo && (
        <CouponModal
          campaignId={campaign.id}
          existingCoupon={couponEditingInfo.existing}
          closeModal={closeModal}
        />
      )}

      <CouponCampaignForm campaign={campaign} />

      <h2 className="text-lg font-medium mt-12 mb-4">Coupon codes</h2>

      <div className="w-full border border-gray-300 rounded overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100 text-sm text-left">
            <th className="font-medium px-6 py-4">Code</th>
            <th className="font-medium px-6 py-4">Total redemption limit</th>
            <th className="font-medium px-6 py-4">Per customer limit</th>
            <th className="font-medium px-6 py-4">Successful redemptions</th>
          </thead>

          <tbody className="[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100">
            {campaign.coupons.map((coupon) => (
              <tr
                key={coupon.id}
                className={clsx('text-sm py-4', {
                  '!bg-red-50': coupon.exhausted,
                })}
              >
                <td className="px-6 py-4">{coupon.code}</td>
                <td className="px-6 py-4">{coupon.limit}</td>
                <td className="px-6 py-4">{coupon.perCustomerLimit}</td>
                <td className="px-6 py-4 flex justify-between items-center">
                  {coupon.totalRedemptions}{' '}
                  <button
                    onClick={() => setCouponEditingInfo({ existing: coupon })}
                  >
                    <GoPencil size={16} />
                  </button>
                </td>
              </tr>
            ))}

            <tr className="">
              <td colSpan={4} className="py-3">
                <button
                  className="flex ml-auto items-center bg-white mx-6 px-4 py-2 rounded border border-gray-700 text-sm"
                  onClick={() => setCouponEditingInfo({})}
                >
                  <FaPlus size={12} className="fill-gray-700 mr-1" />
                  <span>Add a coupon code</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CouponModal = ({
  campaignId,
  existingCoupon,
  closeModal,
}: {
  campaignId: string;
  existingCoupon: CouponCampaign['coupons'][number] | undefined;
  closeModal: () => void;
}): JSX.Element => {
  const { errors, register, handleSubmit } = useForm({
    defaultValues: {
      code: existingCoupon?.code || '',
      limit: existingCoupon?.limit,
      perCustomer: existingCoupon?.perCustomerLimit,
    },
  });

  // TODO(TXN-2387): Ensure this updates the campaign { coupons: [] } cache once we have a real backend.
  // TODO(TXN-2371): Handle coupon code already exists error more gracefully
  const [createCouponMutation] = useMutation<
    CreateCouponCampaignCouponMutation,
    CreateCouponCampaignCouponMutationVariables
  >(gql`
    mutation CreateCouponCampaignCoupon(
      $input: CreateCouponCampaignCouponInput!
    ) {
      createCouponCampaignCoupon(input: $input) {
        coupon {
          id
          code
          limit
          perCustomerLimit
          totalRedemptions
          exhausted
        }
      }
    }
  `);

  const [updateCouponMutation] = useMutation<
    UpdateCouponCampaignCouponMutation,
    UpdateCouponCampaignCouponMutationVariables
  >(gql`
    mutation UpdateCouponCampaignCoupon(
      $input: UpdateCouponCampaignCouponInput!
    ) {
      updateCouponCampaignCoupon(input: $input) {
        coupon {
          id
          code
          limit
          perCustomerLimit
          totalRedemptions
          exhausted
        }
      }
    }
  `);

  return (
    <Modal show onClose={closeModal} width="w-[500px]">
      <form
        onSubmit={handleSubmit(async (data) => {
          try {
            if (existingCoupon) {
              await updateCouponMutation({
                variables: {
                  input: {
                    couponId: existingCoupon.id,
                    couponCampaignId: campaignId,
                    redemptionLimit: data.limit || undefined,
                    perCustomerRedemptionLimit: data.perCustomer || undefined,
                  },
                },
              });
            } else {
              await createCouponMutation({
                variables: {
                  input: {
                    couponCampaignId: campaignId,
                    code: data.code,
                    redemptionLimit: data.limit || undefined,
                    perCustomerRedemptionLimit: data.perCustomer || undefined,
                  },
                },
              });
            }
          } catch {
            return;
          }
          closeModal();
        })}
        className="bg-white rounded-lg p-6 flex flex-col gap-5"
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <p className="text-base uppercase font-semibold">
              {existingCoupon ? 'Edit' : 'Create'} coupon code
            </p>

            <button type="button" className="p-2" onClick={closeModal}>
              <CloseIcon width={16} height={16} className="fill-black" />
            </button>
          </div>

          <Input
            name="code"
            label="code"
            autoFocus
            disabled={!!existingCoupon}
            ref={register(requiredValidation('code'))}
            errorMessage={errors.code?.message}
          />

          <Input
            name="limit"
            label="Total redemption limit"
            type="number"
            ref={register({
              valueAsNumber: true,
              validate: (value) => {
                if (!value) {
                  return true;
                }
                if (existingCoupon && value < existingCoupon.totalRedemptions) {
                  return 'limit must be more than current redemptions';
                }
                return true;
              },
            })}
            errorMessage={errors.limit?.message}
          />
          <Input
            name="perCustomer"
            label="Per customer limit"
            type="number"
            ref={register({ valueAsNumber: true })}
            errorMessage={errors.perCustomer?.message}
          />
        </div>

        <div className="flex justify-end gap-5">
          <Button type="button" variant="outline" onClick={closeModal}>
            Cancel
          </Button>

          <Button type="submit" loading={false}>
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const CouponCampaignForm = ({
  campaign,
}: {
  campaign: CouponCampaign;
}): JSX.Element => {
  const targetedSkus = (
    campaign.targetedVariants?.map((tv) => tv.inventory?.sku) ?? []
  ).filter((s): s is string => Boolean(s));

  // TODO(TXN-2387): Handle already exists updates on campaign name better
  const [updateCouponCampaignMutation, { loading }] = useMutation<
    UpdateCouponCampaignMutation,
    UpdateCouponCampaignMutationVariables
  >(
    gql`
      mutation UpdateCouponCampaign($input: UpdateCouponCampaignInput!) {
        updateCouponCampaign(input: $input) {
          couponCampaign {
            id
            displayName
            description
            status
          }
        }
      }
    `,
    {
      onCompleted: ({ updateCouponCampaign }) =>
        updateCouponCampaign &&
        reset({
          campaignName: updateCouponCampaign.couponCampaign.displayName,
          description: updateCouponCampaign.couponCampaign.description,
          status: updateCouponCampaign.couponCampaign.status,
        }),
    },
  );

  const { register, errors, control, reset, formState, handleSubmit } = useForm(
    {
      defaultValues: {
        campaignName: campaign.displayName,
        description: campaign.description,
        stage: campaign.stage,
        status: campaign.status,
        skus: targetedSkus,
      },
    },
  );

  const skuOptions = targetedSkus.map((s) => ({
    value: s,
    label: s,
  }));

  return (
    <form
      onSubmit={handleSubmit(async (data) =>
        updateCouponCampaignMutation({
          variables: {
            input: {
              id: campaign.id,
              name: data.campaignName,
              description: data.description,
              status: data.status,
            },
          },
        }),
      )}
    >
      <div className="flex items-center justify-between text-primary-500 mb-5">
        <div className="flex items-center">
          <h2 className="text-lg font-medium">Campaign information</h2>
          <a
            className="ml-2 underline text-sm"
            href={campaign.externalCampaignUrl}
            target="_blank"
            rel="noreferrer"
          >
            View campaign in Talon
          </a>
        </div>
        <div className="flex gap-3">
          <Button
            type="button"
            variant="outline"
            onClick={() =>
              reset({
                campaignName: campaign.displayName,
                description: campaign.description,
                stage: campaign.stage,
                status: campaign.status,
                skus: targetedSkus,
              })
            }
          >
            Cancel
          </Button>

          <Button type="submit" loading={loading} disabled={!formState.isDirty}>
            Create
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <Input
          name="campaignName"
          label="Campaign name"
          errorMessage={errors.campaignName?.message}
          ref={register()}
        />
        <TextArea
          rows={3}
          resize
          placeholder="Enter a description"
          name="description"
          label="Description"
          errorMessage={errors.description?.message}
          ref={register()}
        />
        <div className="flex gap-4">
          <div className="w-1/2">
            <Dropdown
              name="status"
              label="status"
              options={campaign.availableStatusTransitions.map((status) => ({
                label: (() => {
                  switch (status) {
                    case 'RUNNING':
                      return 'Running';
                    case 'ARCHIVED':
                      return 'Archived';
                    case 'DISABLED':
                      return 'Disabled';
                    case 'EXPIRED':
                      return 'Expired';
                    case 'SCHEDULED':
                      return 'Scheduled';
                    default:
                      return status;
                  }
                })(),
                value: status,
              }))}
              control={control}
            />
          </div>
          <div className="w-1/2">
            <Dropdown
              name="stage"
              label="Stage"
              disabled
              options={[
                { label: 'First order', value: 'INITIAL' },
                { label: 'Mid-sequence', value: 'MID_SEQUENCE' },
              ]}
              control={control}
            />
          </div>
        </div>

        {skuOptions.length ? (
          <Dropdown
            control={control}
            isMulti
            name="skus"
            label="Targeting variants with sku"
            disabled
            options={skuOptions}
          />
        ) : null}

        <div className="flex gap-4">
          <div className="w-1/2">
            <Input
              name="strategy"
              disabled
              label="Discount type"
              defaultValue={(() => {
                switch (campaign.discountStrategy) {
                  case 'PERCENTAGE':
                    return 'Percentage';
                  case 'FIXED':
                    return 'Fixed';
                }
              })()}
            />
          </div>
          <div className="w-1/2">
            <Input
              name="discount"
              disabled
              label={`Discount amount`}
              defaultValue={(() => {
                switch (campaign.discountAmount.__typename) {
                  case 'CouponCampaignFixedDiscountAmount':
                    return campaign.discountAmount.monetaryValue
                      .formattedAmount;
                  case 'CouponCampaignPercentageDiscountAmount':
                    return `${campaign.discountAmount.value}%`;
                }
              })()}
            />
          </div>
        </div>

        {(campaign.expiryTime || campaign.startTime) && (
          <div className="flex gap-4">
            {campaign.startTime && (
              <div className="w-1/2">
                <Input
                  name="startDate"
                  disabled
                  label="Start date"
                  defaultValue={formatDate(campaign.startTime)}
                />
              </div>
            )}
            {campaign.expiryTime && (
              <div className="w-1/2">
                <Input
                  name="expiryDate"
                  disabled
                  label="Expiry date"
                  defaultValue={formatDate(campaign.expiryTime)}
                />
              </div>
            )}
          </div>
        )}

        {campaign.parameters.length && (
          <>
            <h2 className="text-lg font-medium">Template parameters</h2>
            <div className="grid grid-cols-2 gap-4">
              {campaign.parameters.map((p) => (
                <Input
                  key={p.id}
                  name={p.id}
                  disabled
                  label={p.displayName}
                  defaultValue={(() => {
                    switch (p.__typename) {
                      case 'CouponCampaignBooleanTemplateParameter':
                        return `${p.boolValue}`;
                      case 'CouponCampaignStringTemplateParameter':
                        return p.stringValue;
                      case 'CouponCampaignPercentageTemplateParameter':
                      case 'CouponCampaignNumberTemplateParameter':
                        return p.numberValue;
                      case 'CouponCampaignTimestampTemplateParameter':
                        return formatDateAndTime(new Date(p.timestampValue));
                      case 'CouponCampaignStringArrayTemplateParameter':
                      case 'CouponCampaignSkuListTemplateParameter':
                        return p.value.join(', ');
                    }
                  })()}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </form>
  );
};

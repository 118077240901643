import React from 'react';

import { useQuery, gql } from '@apollo/client';
import { validate } from 'uuid';
import { useParams } from 'react-router-dom';

import {
  GetPackingBundleQuery,
  GetPackingBundleQueryVariables,
} from 'graphql/types';
import { Loading } from 'components/loading';
import { Button } from 'components/button';
import { formatDateAndTime } from 'utils/misc';
import { Tag } from 'components/tag';
import { PackingBundleFiles } from './files';
import { DataField } from '../../components/data-field';

type PackingBundle = NonNullable<
  GetPackingBundleQuery['packingBundles']
>[number];

const headingStyle = 'text-lg font-medium px-4 py-5 border-b border-grey-200';

const bundlesQuery = gql`
  query GetPackingBundle($input: PackingBundleFilterInput!) {
    packingBundles(input: $input) {
      id
      createdAt
      updatedAt
      failureDetails
      url
      status
      files {
        id
        name
      }
      pharmacy {
        id
        name
      }
    }
  }
`;

export default function PackingBundle(): React.ReactElement {
  const { bundleId } = useParams<{ bundleId: string }>();
  const { data, loading, error } = useQuery<
    GetPackingBundleQuery,
    GetPackingBundleQueryVariables
  >(bundlesQuery, {
    variables: {
      input: {
        ids: [bundleId],
      },
    },
    skip: !validate(bundleId),
  });

  const bundles = data?.packingBundles;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error. Please contact #help-technology.</div>;
  }

  if (!bundles || bundles.length === 0) {
    return (
      <div>
        No packing bundle with the id: &quot;{bundleId}&quot; was found.
      </div>
    );
  }

  if (bundles?.length > 1) {
    return (
      <div>
        Error, expected only a single bundle. Please contact #help-technology.
      </div>
    );
  }

  const bundle = bundles[0];

  const buttonDisabled = bundle.url === '';

  return (
    <>
      <div className="w-1/4 mb-5">
        <Button
          fullWidth
          disabled={buttonDisabled}
          onClick={(): void => {
            window.open(bundle.url ?? '', '_blank', 'noreferrer');
          }}
        >
          Download Bundle
        </Button>
      </div>
      <div className="flex flex-col gap-8 lg:flex-row">
        <div className="lg:w-1/2">
          <div className="bg-white shadow overflow-hidden rounded">
            <h2 className={headingStyle}>Bundle</h2>
            <div>
              <DataField label="ID">{bundle.id}</DataField>
              <DataField label="Status">
                <PackingBundleStatus status={bundle.status} />
              </DataField>
              {bundle.failureDetails && (
                <DataField label="Failure Details">
                  {JSON.stringify(bundle.failureDetails, null, 2)}
                </DataField>
              )}
              <DataField label="Fulfilment Centre">
                {bundle.pharmacy.name}
              </DataField>
              <DataField label="Created">
                {formatDateAndTime(bundle.createdAt)}
              </DataField>
              <DataField label="Last Updated">
                {formatDateAndTime(bundle.updatedAt)}
              </DataField>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="bg-white shadow overflow-hidden rounded">
            <h2 className={headingStyle}>Files</h2>
            <PackingBundleFiles bundleId={bundle.id} files={bundle.files} />
          </div>
        </div>
      </div>
    </>
  );
}

function PackingBundleStatus({
  status,
}: {
  status: PackingBundle['status'];
}): React.ReactElement {
  if (status === 'COMPLETE') {
    return (
      <Tag size="small" color="green">
        Completed
      </Tag>
    );
  }
  if (status === 'FAILED') {
    return (
      <Tag size="small" color="red">
        Failed
      </Tag>
    );
  }
  if (status === 'IN_PROGRESS') {
    return (
      <Tag size="small" color="blue">
        In Progress
      </Tag>
    );
  }
  if (status === 'JOB_PUBLISHED') {
    return (
      <Tag size="small" color="blue">
        Job Published
      </Tag>
    );
  }

  return <div>{status}</div>;
}

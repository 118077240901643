import { gql, useMutation } from '@apollo/client';
import { AssignModal } from 'components/assign-modal';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { RadioButton } from 'components/radio-button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useSelectableTable,
} from 'components/table';
import { Tag } from 'components/tag';
import { intervalToDuration } from 'date-fns';
import {
  AsyncConsultationQueueFragment,
  MoveToSyncQueueModalPractitionerBookingWindowFragment,
  UpdateAsyncConsultationQueueEntryPrioritiesMutation,
  UpdateAsyncConsultationQueueEntryPrioritiesMutationVariables,
} from 'graphql/types';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import {
  formatPatientName,
  getConsultationStageColor,
  upperSnakeCaseToCapitalCase,
} from 'utils/misc';
import { formatWindowDay, formatWindowInterval } from 'utils/queues';
import { routes } from 'utils/routes';

type ColumnType = NonNullable<
  AsyncConsultationQueueFragment['entries']
>[number];

const columns: Column<ColumnType>[] = [
  {
    id: 'consultation-patient',
    accessor: 'consultation',
    Header: 'Patient',
    Cell: ({ value }) =>
      value?.customer ? (
        <div>
          <div className="whitespace-nowrap">
            {formatPatientName(value.customer)}
          </div>
          <pre className="text-gray-600 text-xs leading-5">
            {value.customer.id.slice(-6)}
          </pre>
        </div>
      ) : (
        <>-</>
      ),
  },
  {
    id: 'consultation-type',
    accessor: 'consultation',
    Header: 'Type',
    Cell: ({ value }) =>
      value?.stage ? (
        <Tag size="small" color={getConsultationStageColor(value.stage)}>
          {upperSnakeCaseToCapitalCase(value.stage)}
        </Tag>
      ) : (
        <>-</>
      ),
  },
  {
    id: 'review-reason',
    accessor: 'consultation',
    Header: 'Reason',
    Cell: ({ value }) =>
      value?.reviewReason ? (
        <>{upperSnakeCaseToCapitalCase(value.reviewReason)}</>
      ) : (
        <>-</>
      ),
  },
  {
    id: 'consultation-priority',
    accessor: 'consultation',
    Header: 'Priority',
    Cell: ({ value }) => (
      <>{value ? new Array((value.priority || 0) + 1).join('⭐') : '-'}</>
    ),
  },
  {
    accessor: 'joinedQueueAt',
    Header: 'Wait time',
    Cell: ({ value }) => {
      const duration = intervalToDuration({
        end: new Date(),
        start: new Date(value),
      });
      let formattedDuration = '';
      if (duration.years) {
        formattedDuration += `${duration.years}yr `;
      }
      if (duration.months) {
        formattedDuration += `${duration.months}m `;
      }
      if (duration.weeks) {
        formattedDuration += `${duration.weeks}wk `;
      }
      if (duration.days) {
        formattedDuration += `${duration.days}d `;
      }
      if (duration.hours) {
        formattedDuration += `${duration.hours}hr `;
      }
      if (duration.minutes) {
        formattedDuration += `${duration.minutes}min `;
      }
      return <> {formattedDuration}</>;
    },
  },
  {
    accessor: 'preferredPractitioner',
    Header: 'Prac Preference',
    Cell: ({ value }) => <>{value ? value.clinicianName : '-'}</>,
  },
  {
    id: 'assign-action',
    Cell: ({ row }) => {
      const [assignModalActive, setAssignModalActive] = useState(false);
      return (
        <div>
          <Button
            onClick={() => {
              setAssignModalActive(true);
            }}
            variant="outline"
            fullWidth
            type="submit"
          >
            Assign
          </Button>
          <Modal
            show={assignModalActive}
            isAutoOverflow={false}
            onClose={() => setAssignModalActive(false)}
          >
            <AssignModal
              consultationIds={[row.original.consultation?.id || '']}
              onAssigned={() => setAssignModalActive(false)}
            />
          </Modal>
        </div>
      );
    },
  },
];

const MoveToSyncModal = ({
  onClose,
  show,
  consultationIds,
  bookingWindows,
}: {
  onClose: () => void;
  show: boolean;
  consultationIds: string[];
  bookingWindows: NonNullable<
    MoveToSyncQueueModalPractitionerBookingWindowFragment[]
  >;
}) => {
  const { watch, register, handleSubmit } = useForm<{
    targetBookingId: string;
  }>({
    defaultValues: {
      targetBookingId: bookingWindows.find((w) => w.available)?.id || '',
    },
  });

  const submit = handleSubmit(async () => {
    onClose();
  });

  return (
    <Modal show={show} onClose={onClose}>
      <div className="bg-gray-200 p-6">
        <div className="text-lg font-semibold">
          Allocate {consultationIds.length > 1 ? 'patients' : 'patient'} to Sync
          Queue
        </div>
        <div className="text-sm">
          Please allocate the{' '}
          <span className="font-semibold">
            {consultationIds.length}{' '}
            {consultationIds.length > 1 ? 'patients' : 'patient'}
          </span>{' '}
          to a new time slot.
        </div>
        <table
          className="bg-white shadow w-full text-center border-collapse mt-8"
          style={{
            borderStyle: 'hidden',
          }}
        >
          <tbody className="text-sm">
            {bookingWindows.map((w) => {
              const day = formatWindowDay(w);

              const interval = formatWindowInterval(w);

              return (
                <tr
                  key={w.id}
                  className={
                    w.available ? w.override?.id && 'bg-red-100' : 'bg-gray-300'
                  }
                >
                  <td className="border border-gray-300 py-2.5 px-2 text-center">
                    {w.available ? (
                      <RadioButton
                        id={w.id}
                        ref={register()}
                        name="targetBookingId"
                        selected={watch().targetBookingId === w.id}
                        customIcon={<FaCheck className="my-0 mx-auto" />}
                      />
                    ) : (
                      ' - '
                    )}
                  </td>
                  <td className="border border-gray-300 py-2.5 px-2">{day}</td>
                  <td className="border border-gray-300 py-2.5 px-2">
                    {interval}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <form className="mt-6" onSubmit={submit}>
          <div className="flex flex-row gap-x-5 mt-8">
            <Button
              fullWidth
              loading={false}
              disabled={false}
              color="danger"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              loading={false}
              disabled={false}
              type="submit"
              color="primary"
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const AsyncQueueTable = ({
  rowData,
  bookingWindows,
}: {
  bookingWindows: NonNullable<
    MoveToSyncQueueModalPractitionerBookingWindowFragment[]
  >;
  rowData: NonNullable<AsyncConsultationQueueFragment['entries']>;
}) => {
  const [assignModal, setAssignModal] = useState(false);
  const [moveToSyncModal, setMoveToSyncModal] = useState(false);
  const history = useHistory();
  const data = useMemo(() => rowData, [rowData]);
  const tableInstance = useSelectableTable({
    columns,
    data,
  });

  const [
    updateQueueEntryPriorities,
    { loading: loadingUpdateAsyncConsultationQueueEntryPriorities, client },
  ] = useMutation<
    UpdateAsyncConsultationQueueEntryPrioritiesMutation,
    UpdateAsyncConsultationQueueEntryPrioritiesMutationVariables
  >(
    gql`
      mutation UpdateAsyncConsultationQueueEntryPriorities(
        $input: UpdateAsyncConsultationQueueEntryPrioritiesInput!
      ) {
        updateAsyncConsultationQueueEntryPriorities(input: $input) {
          consultations {
            id
            priority
          }
        }
      }
    `,
    {
      onCompleted: () => {
        // changing priorities causes the order to change
        // we clear the cache to force a refresh and
        // get the up to date order
        client.cache.evict({
          fieldName: 'asyncConsultationQueue',
        });
        client.cache.gc();
      },
    },
  );

  return (
    <div>
      <div className="flex space-x-2 py-4">
        <Button
          // TODO: tel-437
          disabled={true}
          onClick={() => setMoveToSyncModal(true)}
        >
          Move to sync
        </Button>
        <MoveToSyncModal
          show={moveToSyncModal}
          onClose={() => setMoveToSyncModal(false)}
          bookingWindows={bookingWindows}
          consultationIds={tableInstance.selectedFlatRows.map(
            (row) => row.original.consultation?.id || '',
          )}
        />
        <Button
          loading={loadingUpdateAsyncConsultationQueueEntryPriorities}
          disabled={tableInstance.selectedFlatRows.length === 0}
          onClick={async () => {
            const updates = tableInstance.selectedFlatRows.map((r) => ({
              id: r.original.id,
              priority: (r.original.consultation?.priority || 0) + 1,
            }));

            await updateQueueEntryPriorities({
              variables: { input: { updates } },
            });
          }}
        >
          ⭐️ Prioritise
        </Button>
        <Button
          loading={loadingUpdateAsyncConsultationQueueEntryPriorities}
          disabled={tableInstance.selectedFlatRows.length === 0}
          onClick={async () => {
            const updates = tableInstance.selectedFlatRows.map((r) => ({
              id: r.original.id,
              priority: Math.max(
                (r.original.consultation?.priority || 0) - 1,
                0,
              ),
            }));

            await updateQueueEntryPriorities({
              variables: { input: { updates } },
            });
          }}
        >
          ☆ Deprioritise
        </Button>
        <Button
          disabled={tableInstance.selectedFlatRows.length === 0}
          onClick={() => setAssignModal(true)}
        >
          Assign {tableInstance.selectedFlatRows.length} selected
        </Button>
        <Modal
          show={assignModal}
          isAutoOverflow={false}
          onClose={() => setAssignModal(false)}
        >
          <AssignModal
            consultationIds={tableInstance.selectedFlatRows.map(
              (row) => row.original.consultation?.id || '',
            )}
            onAssigned={() => setAssignModal(false)}
          />
        </Modal>
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.rows?.map((row) => {
            tableInstance.prepareRow(row);
            return (
              <TableRow row={row} key={row.id}>
                {row.cells.map((cell) => (
                  <TableCell
                    onClick={(): void => {
                      if (
                        cell.column.id !== 'selection' &&
                        cell.column.id !== 'assign-action'
                      ) {
                        history.push(
                          `${routes.consultations}/${cell.row.original.consultation?.id}`,
                        );
                      }
                    }}
                    key={`${row.id}-${cell.column.id}`}
                    cell={cell}
                  />
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

AsyncQueueTable.queueTableFragment = gql`
  fragment AsyncConsultationQueue on AsyncConsultationQueue {
    entries {
      id
      joinedQueueAt
      preferredPractitioner {
        id
        clinicianName
      }
      consultation {
        id
        type
        stage
        priority
        reviewReason
        customer {
          id
          fullName
        }
      }
    }
  }
`;

AsyncQueueTable.moveToSyncQueueModalFragment = gql`
  fragment MoveToSyncQueueModalPractitionerBookingWindow on PractitionerBookingWindow {
    id
    windowId
    available
    startAt
    endAt
    problemType
    override {
      id
    }
  }
`;

export default AsyncQueueTable;

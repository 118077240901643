import clsx from 'clsx';
import { PractitionerBookingWindowFragment } from 'graphql/types';
import React, { useState } from 'react';
import NoPickupQueueTable from './no-pickup-queue-table';
import PractitionerBookingsTable from './practitioner-bookings-table';
import {
  QueueSummary,
  QueueSummaryWindowFragmentDoc,
} from './summary/queue-summary';
import { formattedWindowTimezone } from 'utils/queues';
import { QueueFilter } from './summary/queue-filter';
import { ProblemType } from '@eucalyptusvc/lib-adapters';
import { gql } from '@apollo/client';
import { BookingRescheduleModalPractitionerBookingWindowDoc } from './booking-reschedule-modal';
enum QueueView {
  NO_PICKUP_QUEUE = 'NO_PICKUP_QUEUE',
  PRACTITIONER_BOOKINGS_QUEUE = 'PRACTITIONER_BOOKINGS_QUEUE',
}
export const PractitionerBookingWindowsDoc = gql`
  ${QueueSummaryWindowFragmentDoc}
  ${BookingRescheduleModalPractitionerBookingWindowDoc}

  fragment PractitionerBookingWindow on PractitionerBookingWindow {
    ...BookingRescheduleModalPractitionerBookingWindow
    ...QueueSummaryWindow
  }
`;

const SyncQueues = ({
  problemType,
  practitionerBookingWindows,
  refetch,
}: {
  practitionerBookingWindows: NonNullable<PractitionerBookingWindowFragment>[];
  problemType: ProblemType;
  refetch: () => void;
}): React.ReactElement => {
  const [queueView, setQueueView] = useState<QueueView>(
    QueueView.PRACTITIONER_BOOKINGS_QUEUE,
  );

  const windowsForSelectedProblemType = React.useMemo(() => {
    return (
      practitionerBookingWindows?.filter(
        (pbw) => pbw.problemType === problemType,
      ) || []
    );
  }, [practitionerBookingWindows, problemType]);

  const selectedTabClasses = 'border border-green-900 bg-white';
  const deselectedTabClasses = 'border border-gray-400 bg-inherit';

  const totalBookingsForSelectedProblemType =
    windowsForSelectedProblemType.reduce(
      (sum, w) => sum + w.unassignedBookingsCount,
      0,
    );

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex font-light">
          <button
            className={clsx(
              'text-center cursor-pointer px-4 py-3 text-gray-900 rounded-l-sm',
              queueView === QueueView.PRACTITIONER_BOOKINGS_QUEUE
                ? selectedTabClasses
                : deselectedTabClasses,
            )}
            onClick={() => setQueueView(QueueView.PRACTITIONER_BOOKINGS_QUEUE)}
          >
            Prescriber queue
          </button>
          <button
            className={clsx(
              'text-center cursor-pointer px-4 py-3 text-gray-900 rounded-r-sm',
              queueView === QueueView.NO_PICKUP_QUEUE
                ? selectedTabClasses
                : deselectedTabClasses,
            )}
            onClick={() => setQueueView(QueueView.NO_PICKUP_QUEUE)}
          >
            No pickup queue
          </button>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-sm text-gray-700">
          Dates and times below are in {formattedWindowTimezone}.
        </div>
      </div>
      <div className="space-y-8">
        <div className="flex space-x-6 mt-2">
          <div className="flex-auto w-2/3">
            {windowsForSelectedProblemType && problemType && (
              <QueueSummary
                windows={windowsForSelectedProblemType}
                problemType={problemType}
                onUpdate={refetch}
              />
            )}
          </div>
        </div>
        {queueView === QueueView.PRACTITIONER_BOOKINGS_QUEUE && (
          <div>
            <QueueFilter />
            <PractitionerBookingsTable
              problemType={problemType}
              practitionerBookingWindows={windowsForSelectedProblemType}
              onBookingUpdated={async () => {
                refetch();
              }}
              totalBookings={totalBookingsForSelectedProblemType}
            />
          </div>
        )}
        {queueView === QueueView.NO_PICKUP_QUEUE && (
          <NoPickupQueueTable problemType={problemType} />
        )}
      </div>
    </div>
  );
};

export default SyncQueues;

import { gql, useApolloClient } from '@apollo/client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import {
  RepeatPurchaseSyncGroupOrderFragment,
  RepeatPurchaseSyncGroupOrderMutation,
  RepeatPurchaseSyncGroupOrderMutationVariables,
} from 'graphql/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import { Input } from 'components/input';
import { Checkbox } from 'components/checkbox';

interface Props {
  syncGroup: RepeatPurchaseSyncGroupOrderFragment;
  onClose: () => void;
}

const repeatPurchaseSyncGroupOrderDocument = gql`
  mutation repeatPurchaseSyncGroupOrder(
    $input: RepeatPurchaseSyncGroupOrderForFreeInput!
  ) {
    repeatPurchaseSyncGroupOrderForFree(input: $input) {
      purchaseSyncGroup {
        id
        status
        nextOrderDueAt
        actions {
          id
        }
        sequenceContexts {
          id
          status
          nextMoveDue
          nextOrderDue
          mermaid
        }
      }
    }
  }
`;

function RepeatPurchaseSyncGroupOrder(props: Props) {
  const apollo = useApolloClient();
  const [loading, setLoading] = useState(false);

  let orderId;
  let lineItems;
  let canReuseDispensements;
  let canRepeat;
  for (const a of props.syncGroup.actions ?? []) {
    if (a.__typename === 'RepeatPurchaseSyncGroupForFreeOrderAction') {
      orderId = a.order?.id;
      canReuseDispensements = a.canReuseDispensements;
      lineItems = a.order?.consignments.map((c) => c.lineItems).flat();
      canRepeat = true;
      break;
    }
  }

  const form = useForm<{
    reason: string;
    orderId: string;
    reuseDispensement: boolean;
    idempotencyKey: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      orderId,
      idempotencyKey: v4(),
      reuseDispensement: false,
    },
  });

  const repeatLastOrder = form.handleSubmit(async (fields): Promise<void> => {
    setLoading(true);

    try {
      await apollo.mutate<
        RepeatPurchaseSyncGroupOrderMutation,
        RepeatPurchaseSyncGroupOrderMutationVariables
      >({
        mutation: repeatPurchaseSyncGroupOrderDocument,
        variables: {
          input: {
            purchaseSyncGroupId: props.syncGroup.id,
            reason: fields.reason,
            orderId: fields.orderId,
            idempotencyKey: fields.idempotencyKey,
            reuseDispensement: fields.reuseDispensement,
          },
        },
      });
    } finally {
      setLoading(false);
      props.onClose();
    }
  });

  const pluralisedSequence =
    (props.syncGroup.sequenceContexts?.length ?? 0) > 1
      ? 'sequences'
      : 'sequence';

  return (
    <Modal show onClose={props.onClose}>
      <form
        onSubmit={repeatLastOrder}
        className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mx-auto text-gray-800"
      >
        <h3 className="text-lg leading-6 font-medium mb-3">
          Repeat last order
        </h3>
        {canRepeat ? (
          <>
            <p className="mb-3">
              The last order associated with {pluralisedSequence} will be
              repeated for <span className="font-semibold">free</span>.
            </p>

            {lineItems && lineItems.length && (
              <div className="mb-4">
                <p>The line items in this order are:</p>
                <ul className="list-disc list-inside text-sm text-slate-600 mt-2">
                  {lineItems.map((li) => (
                    <li key={li.id}>
                      {li.variant?.name} <strong>(x{li.quantity}</strong>)
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <input
              name="idempotencyKey"
              className="hidden"
              ref={form.register({ required: true })}
            />
            <input
              name="orderId"
              className="hidden"
              ref={form.register({ required: true })}
            />
            <Input
              name="reason"
              placeholder="Reason"
              errorMessage={form.errors.reason?.message}
              ref={form.register({ required: 'Reason is required.' })}
            />
            <div className="mt-4">
              <Checkbox
                label="Reuse Dispensements"
                ref={form.register()}
                disabled={!canReuseDispensements}
                description="This will cancel the previous dispensement on the above product so that the script can be reused."
                name="reuseDispensement"
              />
            </div>
          </>
        ) : (
          <p className="mb-3">
            <span className="capitalize">{pluralisedSequence}</span> cannot have
            their last order repeated.
          </p>
        )}
        <div className="flex gap-2 mt-6 justify-end">
          <Button variant="outline" onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            disabled={!form.formState.isValid || !canRepeat}
            color="success"
            type="submit"
            loading={loading}
          >
            Resume
          </Button>
        </div>
      </form>
    </Modal>
  );
}

RepeatPurchaseSyncGroupOrder.fragment = gql`
  fragment RepeatPurchaseSyncGroupOrder on PurchaseSyncGroup {
    id
    nextOrderDueAt
    sequenceContexts {
      id
    }
    actions {
      id
      ... on RepeatPurchaseSyncGroupForFreeOrderAction {
        canReuseDispensements
        order {
          id
          consignments {
            id
            lineItems {
              id
              quantity
              variant {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export { RepeatPurchaseSyncGroupOrder };

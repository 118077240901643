import { gql, useQuery } from '@apollo/client';
import { DiscountCode, DiscountCodeOrderByInput, Product } from 'graphql/types';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { Tag } from 'components/tag';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useOrderBy,
  usePageIndex,
  usePaginatingSortingTable,
} from 'components/table';
import { ReactElement, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column, TableInstance } from 'react-table';
import { routes } from 'utils/routes';
import { isPast } from 'date-fns';
import { formatDateAndTime } from 'utils/misc';

const TABLE_PAGE_SIZE = 30;

const discountsQuery = gql`
  query DiscountCodes(
    $orderBy: [DiscountCodeOrderByInput!]!
    $pageSize: Int
    $skip: Int
  ) {
    discountCodes(orderBy: $orderBy, take: $pageSize, skip: $skip) {
      id
      name
      code
      type
      amount
      expiresAt
      createdAt
      products {
        id
        name
      }
    }
    discountCodesCount
  }
`;

const LegacyDiscounts = (): ReactElement => {
  const history = useHistory();
  const orderBy = useOrderBy({ defaultValue: 'createdAt_desc' });
  const pageIndex = usePageIndex();
  const { data, loading } = useQuery<
    { discountCodes: DiscountCode[]; discountCodesCount: number },
    {
      orderBy: DiscountCodeOrderByInput[];
      pageSize: number;
      skip: number;
    }
  >(discountsQuery, {
    variables: {
      orderBy: orderBy,
      pageSize: TABLE_PAGE_SIZE,
      skip: pageIndex * TABLE_PAGE_SIZE,
    },
  });

  const total = data?.discountCodesCount || 0;
  const discounts = useMemo(() => data?.discountCodes || [], [data]);

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: discounts,
    pageNumber: Math.ceil(total / TABLE_PAGE_SIZE),
    orderBy,
    pageIndex,
  });

  const handleCellClick = useMemo(
    () =>
      (discountCodeId: string): void => {
        history.push(`${routes.legacyDiscounts}/${discountCodeId}`);
      },
    [history],
  );

  return (
    <DiscountsRenderer
      loading={loading}
      tableInstance={tableInstance}
      total={total}
      onCellClick={handleCellClick}
    />
  );
};

interface DiscountsRendererProps {
  loading: boolean;
  tableInstance: TableInstance<DiscountCode>;
  total: number;
  onCellClick: (discountCodeId: string) => void;
}

const DiscountsRenderer = ({
  loading,
  tableInstance,
  total,
  onCellClick,
}: DiscountsRendererProps): ReactElement => {
  if (loading) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end">
        <div className="mb-5">
          <Link to={routes.createLegacyDiscount}>
            <Button fullWidth variant="outline">
              Create New
            </Button>
          </Link>
        </div>
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          <>
            {tableInstance.page.map(
              (row) => (
                tableInstance.prepareRow(row),
                (
                  <TableRow row={row} key={row.id}>
                    <>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (cell.column.id !== 'selection') {
                              onCellClick(cell.row.original.id);
                            }
                          }}
                        />
                      ))}
                    </>
                  </TableRow>
                )
              ),
            )}
          </>
        </TableBody>
      </Table>
      <Pagination total={total} tableInstance={tableInstance} />
    </>
  );
};

const ProductsCell = (
  cell: CellProps<DiscountCode, Product[]>,
): React.ReactElement => (
  <div className="mt-1">
    {cell.value?.map((product, i) => (
      <div key={`${i}_${product.id}`} className="inline-block mr-1 mb-1">
        <Tag size="small" color="gray">
          {product.name}
        </Tag>
      </div>
    ))}
  </div>
);

const columns: Column<DiscountCode>[] = [
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: (c) => <div>{formatDateAndTime(c.value)}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Code',
    accessor: 'code',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Status',
    accessor: 'expiresAt',
    Cell: (c) => {
      const isExpired = !!(c.value && isPast(Date.parse(c.value)));
      return (
        <div className="mt-1">
          <div className="inline-block mr-1 mb-1">
            {isExpired ? (
              <Tag size="small" color="red">
                Expired
              </Tag>
            ) : (
              <Tag size="small" color="green">
                Active
              </Tag>
            )}
          </div>
        </div>
      );
    },
    className: 'w-1/6',
  },
  {
    Header: 'Products',
    accessor: 'products',
    Cell: ProductsCell,
    className: 'w-1/6',
  },
];

export default LegacyDiscounts;

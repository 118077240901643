import { gql, useMutation, useQuery } from '@apollo/client';
import {
  DiscountCode,
  UpsertDiscountCodeMutation,
  UpsertDiscountCodeMutationVariables,
} from 'graphql/types';
import { useAlert } from 'alert';
import { Loading } from 'components/loading';
import { useNotifications } from 'notifications';
import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { subDays } from 'date-fns';
import {
  DiscountCodeForm,
  DiscountCodeFormFields,
  upsertDiscountCodeMutation,
  mapDiscountCodeFormFieldsToGraphQLUpsertData,
  mapGraphQLDiscountCodeToDiscountCodeFormFields,
} from 'components/discount-form';

const discountCodeQuery = gql`
  query DiscountCode($discountId: String!) {
    discountCode(where: { id: $discountId }) {
      id
      name
      code
      type
      amount
      maxAllowedUses
      allowedCustomerType
      stages
      expiresAt
      startsAt
      products {
        id
        name
      }
    }
  }
`;

const LegacyDiscount = (): ReactElement => {
  const showNotification = useNotifications();
  const showAlert = useAlert();
  const { discountId } = useParams<{ discountId: string }>();
  const { data, loading, error, refetch } = useQuery<{
    discountCode: DiscountCode;
  }>(discountCodeQuery, {
    variables: { discountId },
  });
  const [upsertDiscountCode, { loading: updatingInProgress }] = useMutation<
    UpsertDiscountCodeMutation,
    UpsertDiscountCodeMutationVariables
  >(upsertDiscountCodeMutation, {
    onCompleted: () =>
      showNotification({ type: 'success', message: 'Discount Code updated.' }),
    onError: () =>
      showNotification({
        type: 'error',
        message: 'Error updating Discount Code.',
      }),
  });

  const discountCode = data?.discountCode;

  const handleSubmitUpdate = useMemo(
    () =>
      async (
        fields: DiscountCodeFormFields,
        reset: (values?: DiscountCodeFormFields) => void,
      ): Promise<void> => {
        const updateResponse = await upsertDiscountCode({
          variables: {
            input: {
              id: discountId,
              ...mapDiscountCodeFormFieldsToGraphQLUpsertData(fields),
            },
          },
        });

        reset(
          mapGraphQLDiscountCodeToDiscountCodeFormFields(
            updateResponse.data?.upsertDiscountCode?.discountCode ||
              (await refetch()).data.discountCode,
          ),
        );
      },
    [discountId, upsertDiscountCode, refetch],
  );

  const handleDeactivation = useMemo(
    () =>
      async (fields: DiscountCodeFormFields): Promise<void> => {
        if (
          await showAlert({
            content: `Are you sure you want to deactivate this discount? No new patients will be able to use it.\n
          Note: all the existing patients will continue to get a discount on their recurring order payment. If you want to delete the discount for all patients, please contact engineering team.`,
          })
        ) {
          const discountCodeData =
            mapDiscountCodeFormFieldsToGraphQLUpsertData(fields);
          await upsertDiscountCode({
            variables: {
              input: {
                id: discountId,
                ...discountCodeData,
                expiresAt: subDays(new Date(), 9999).toISOString(),
              },
            },
          });
        }
      },
    [discountId, showAlert, upsertDiscountCode],
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !discountCode) {
    return <div>Failed to retrieve discount code.</div>;
  }

  return (
    <DiscountCodeForm
      type="update"
      initialValues={mapGraphQLDiscountCodeToDiscountCodeFormFields(
        discountCode,
      )}
      onSubmit={handleSubmitUpdate}
      submitting={updatingInProgress}
      delete={handleDeactivation}
    />
  );
};

export default LegacyDiscount;

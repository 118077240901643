import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, generatePath, useHistory } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { Loading } from '../../components/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from '../../components/table';
import { Tag } from '../../components/tag';
import {
  BrandConditionFragmentFragment,
  GetOfferingsListQuery,
  GetOfferingsListQueryVariables,
  SetBrandConditionDefaultOfferingMutation,
  SetBrandConditionDefaultOfferingMutationVariables,
} from '../../graphql/types';
import {
  upperSnakeCaseToCapitalCase,
  getProblemTypeColor,
  getProblemTypeEmoji,
} from '../../utils/misc';
import { buildRoute, routes } from '../../utils/routes';
import { useHasPermissions } from 'components/permissions';
import { OfferingSelectModal } from 'components/flexi-plans/offering-select-modal';
import { useNotifications } from 'notifications';

const brandConditionFragment = gql`
  fragment BrandConditionFragment on BrandCondition {
    id
    type
    defaultOfferingSelection {
      id
      offering {
        id
        friendlyName
        description
      }
      sequenceSelections {
        id
        sequence {
          id
          internalName
        }
      }
    }
  }
`;

type Offering = NonNullable<GetOfferingsListQuery['offerings']>[0];

type BrandConditionCardProps = {
  brandCondition: BrandConditionFragmentFragment;
  brandOfferings: Offering[];
  showModal: boolean;
  loading: boolean;
  setShowModal: (value: boolean) => void;
};

function BrandConditionCard({
  brandCondition: bc,
  brandOfferings,
  showModal,
  setShowModal,
  loading,
}: BrandConditionCardProps) {
  const canEditOfferings = useHasPermissions(['EDIT_OFFERINGS']);
  const showNotifications = useNotifications();

  const problemTypeOfferings = useMemo(
    () =>
      brandOfferings.filter(
        (bo) => bo.problemTypes.includes(bc.type) && bo.status === 'AVAILABLE',
      ),
    [brandOfferings, bc.type],
  );

  const setDefaultOfferings = useMutation<
    SetBrandConditionDefaultOfferingMutation,
    SetBrandConditionDefaultOfferingMutationVariables
  >(
    gql`
      mutation SetBrandConditionDefaultOffering(
        $input: SetDefaultOfferingInput!
      ) {
        setDefaultOffering(input: $input) {
          brandCondition {
            ...BrandConditionFragment
          }
        }
      }
      ${brandConditionFragment}
    `,
    {
      onCompleted: () => {
        setShowModal(false);
        showNotifications({
          type: 'success',
          message: 'Default offering selections updated',
        });
      },
      onError: () => {
        showNotifications({
          type: 'error',
          message: 'Failed to update default offering selection',
        });
      },
    },
  );

  return (
    <div
      key={bc.id}
      className="flex flex-col space-y-2 py-2 px-4 shadow-md bg-white w-96 flex-none"
    >
      {showModal && (
        <OfferingSelectModal
          ctaText="Set Default"
          onClose={() => setShowModal(false)}
          offerings={problemTypeOfferings}
          onOfferingSelected={(selection) =>
            setDefaultOfferings[0]({
              variables: {
                input: {
                  offering: {
                    offeringId: selection.offeringId,
                    sequenceSelections: selection.sequenceSelections,
                  },
                  problemType: bc.type,
                },
              },
            })
          }
          selection={{
            offeringId: bc.defaultOfferingSelection?.offering?.id,
            sequences: bc.defaultOfferingSelection?.sequenceSelections?.map(
              (s) => ({
                sequenceId: s.sequence?.id ?? '',
                isDisabled: false,
              }),
            ),
          }}
          loading={loading}
          updating={setDefaultOfferings[1].loading}
        />
      )}
      <div className="flex flex-row justify-between">
        <h3 className="text-md font-semibold">
          {upperSnakeCaseToCapitalCase(bc.type)}
        </h3>
        <Button
          variant="solid"
          disabled={!canEditOfferings}
          onClick={() => setShowModal(true)}
          size="small"
          hoverText={
            !canEditOfferings
              ? 'This action can only be performed by users with the "EDIT_OFFERINGS" permission'
              : ''
          }
        >
          Edit
        </Button>
      </div>
      <div className="text-sm">
        <p className="text-gray-700 pb-3">
          {bc.defaultOfferingSelection?.offering ? (
            <Link
              target="_blank"
              className="cursor-pointer"
              to={buildRoute.offering(bc.defaultOfferingSelection.offering.id)}
            >
              {bc.defaultOfferingSelection.offering.friendlyName}
            </Link>
          ) : (
            'Not configured'
          )}
        </p>
        <ul className="list-inside list-disc text-gray-900">
          {bc.defaultOfferingSelection?.sequenceSelections?.map((ss) => (
            <li className="truncate" key={ss.id}>
              <Link
                target="_blank"
                className="cursor-pointer"
                to={buildRoute.sequence(ss.sequence?.id ?? '')}
              >
                {ss.sequence?.internalName}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export const ListOfferings = (): React.ReactElement => {
  const history = useHistory();
  const [shownModals, setShownModals] = useState(new Set<string>());

  const {
    data: currentData,
    previousData,
    loading,
    error,
  } = useQuery<GetOfferingsListQuery, GetOfferingsListQueryVariables>(
    gql`
      query getOfferingsList($includeMermaid: Boolean!) {
        offerings {
          id
          friendlyName
          advertisedName
          problemTypes
          status
          sequenceSets {
            id
            prescribableFilter
          }
          ...OfferingSelections
        }
        brandConditions {
          ...BrandConditionFragment
        }
      }
      ${OfferingSelectModal.offeringFragment}
      ${brandConditionFragment}
    `,
    {
      variables: {
        includeMermaid: shownModals.size > 0,
      },
    },
  );

  const data = currentData ?? previousData;

  const goToOffering = useCallback(
    (cell: Cell<Offering, unknown>) => {
      if (cell.column.id !== 'selection') {
        history.push(
          generatePath(routes.offering, {
            offeringId: cell.row.original.id,
          }),
        );
      }
    },
    [history],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data: data?.offerings ?? [],
    pageNumber: 1,
    disableSortBy: true,
  });

  if (loading && !data) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        Error - Unable to load offerings, please contact #help-technology.
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="flex flex-col space-y-2 flex-grow">
          <h2 className="text-md font-medium text-gray-800">
            Default offering
          </h2>
          <p className="mb-2 italic text-sm text-gray-700">
            Problem type defaults dictate what a customer will purchase at CP.
            <br />
            Experiments may be running in{' '}
            <a
              href="https://app.launchdarkly.com/default/production/features/xp_default_offering/targeting"
              className="underline"
            >
              this
            </a>{' '}
            feature flag.
          </p>
        </div>
        <Link to={routes.createOffering} className="flex-none">
          <Button fullWidth variant="outline">
            Create New
          </Button>
        </Link>
      </div>
      <div className="flex flex-row gap-4 overflow-x-auto flex-grow py-4 mb-4">
        {data?.brandConditions?.map((bc) => (
          <BrandConditionCard
            key={bc.id}
            brandCondition={bc}
            brandOfferings={data.offerings ?? []}
            loading={loading}
            showModal={shownModals.has(bc.id)}
            setShowModal={(show) =>
              setShownModals((prev) => {
                const next = new Set(prev);
                if (show) {
                  next.add(bc.id);
                } else {
                  next.delete(bc.id);
                }
                return next;
              })
            }
          />
        ))}
      </div>
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.page.map(
            (row) => (
              tableInstance.prepareRow(row),
              (
                <TableRow row={row} key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell
                      key={`${cell.row.original.id}-${cell.column.id}`}
                      cell={cell}
                      onClick={goToOffering}
                    />
                  ))}
                </TableRow>
              )
            ),
          )}
        </TableBody>
      </Table>
    </>
  );
};

const columns: Column<Offering>[] = [
  {
    Header: 'Name',
    Cell: (c) => <div>{c.row.original.friendlyName}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Advertised Name',
    Cell: (c) => <div>{c.row.original.advertisedName}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Problem Types',
    Cell: (c) => (
      <div className="mt-1">
        {c.row.original.problemTypes?.map((problemType, i) => (
          <div key={`${i}_${problemType}`} className="inline-block mr-1 mb-1">
            <Tag size="small" color={getProblemTypeColor(problemType)}>
              <span role="img" className="pr-1">
                {problemType && getProblemTypeEmoji(problemType)}
              </span>
              {problemType?.replaceAll('_', ' ')}
            </Tag>
          </div>
        ))}
      </div>
    ),
    className: 'w-1/5',
  },
  {
    Header: 'Requires Prescription',
    Cell: (c) => {
      const containsPrescribableFilter = c.row.original.sequenceSets.some(
        (ss) => ss.prescribableFilter === true,
      );

      return <div>{containsPrescribableFilter ? 'Yes' : 'No'}</div>;
    },
    className: 'w-1/5',
  },
  {
    Header: 'Status',
    Cell: (c) => {
      if (c.row.original.status === 'AVAILABLE') {
        return (
          <Tag size="small" color="green">
            Available
          </Tag>
        );
      }
      if (c.row.original.status === 'UNAVAILABLE') {
        return (
          <Tag size="small" color="blue">
            Unavailable
          </Tag>
        );
      }
      if (c.row.original.status === 'DRAFT') {
        return (
          <Tag size="small" color="gray">
            Draft
          </Tag>
        );
      }
      return <div>{c.row.original.status}</div>;
    },
    className: 'w-1/5',
  },
];

import clsx from 'clsx';

export function GridTransition(props: {
  children: React.ReactNode;
  show: boolean;
}) {
  return (
    <div
      className={clsx(
        'grid transition-all duration-300 ease-in-out',
        props.show ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
      )}
    >
      <div className="overflow-hidden">{props.children}</div>
    </div>
  );
}

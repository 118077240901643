import SyncQueues, { PractitionerBookingWindowsDoc } from './sync';
import AsyncQueueTable from './async';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { useLocalStorage } from 'react-use';
import { useHistory } from 'react-router-dom';
import { ProblemType } from '@eucalyptusvc/lib-adapters';
import { usePracBookingWindowDates } from 'utils/queues';
import { gql, useQuery } from '@apollo/client';
import {
  AsyncConsultationQueueInput,
  ConsultationStage,
  QueuesQuery,
  QueuesQueryVariables,
  ReviewReason,
} from 'graphql/types';
import { isWithinInterval } from 'date-fns';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';
import clsx from 'clsx';
import { useForm, useWatch } from 'react-hook-form';
import { reasonOptions } from 'utils/dropdown-options';
import { DoctorSelect } from 'components/doctor-select';
import { Dropdown, Option } from 'components/dropdown';
import { useState } from 'react';
import { Loading } from 'components/loading';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const typeFilterOptions: Option<ConsultationStage>[] = [
  { label: 'Initial', value: 'INITIAL' },
  { label: 'Followup', value: 'FOLLOW_UP' },
  { label: 'Review', value: 'REVIEW' },
];

const priorityFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: '⭐', value: '1' },
  { label: '⭐⭐', value: '2' },
  { label: '⭐⭐⭐', value: '3' },
];

const tablePageSize = 30;

const Queues = () => {
  const history = useHistory();
  const [queueType, setQueueType] = useLocalStorage<'SYNC' | 'ASYNC'>(
    'queue-type',
    'SYNC',
  );
  const [selectedProblemType, setSelectedProblemType] = useLocalStorage<
    ProblemType | undefined
  >('admin-queue-problem-type');
  const [pageDetails, setPageDetails] = useState({
    currentPageNumber: 1,
    pageNumberToToken: new Map([[1, '']]),
  });

  const asyncFiltersForm = useForm<{
    consultationStages: ConsultationStage[];
    preferredPractitioners: string[];
    priority: string;
    reviewReasons: ReviewReason;
  }>({
    reValidateMode: 'onChange',
  });

  const featureFlagClient = useFeatureFlagClient();
  const ffAsyncConsultsEnabled = featureFlagClient.getBoolean(
    'ff-async-consults-enabled',
  );
  const asyncFiltersFormData = useWatch<{
    consultationStages: ConsultationStage[];
    preferredPractitioners: string[];
    priority: string;
    reviewReasons: ReviewReason[];
  }>({
    defaultValue: {
      consultationStages: [],
      preferredPractitioners: [],
      priority: '',
      reviewReasons: [],
    },
    control: asyncFiltersForm.control,
  });

  const asyncConsultationQueueInput: AsyncConsultationQueueInput = {
    consultationStages: asyncFiltersFormData.consultationStages || undefined,
    preferredPractitionerIds:
      asyncFiltersFormData.preferredPractitioners || undefined,
    priority: parseInt(asyncFiltersFormData.priority || '') || undefined,
    problemType: selectedProblemType || 'WEIGHT_LOSS',
    reviewReasons: asyncFiltersFormData.reviewReasons || undefined,
    pageRequest: {
      pageSize: tablePageSize,
      pageToken: pageDetails.pageNumberToToken.get(
        pageDetails.currentPageNumber,
      ),
    },
  };

  const dates = usePracBookingWindowDates();

  const { data, refetch, client } = useQuery<QueuesQuery, QueuesQueryVariables>(
    gql`
      ${PractitionerBookingWindowsDoc}
      ${AsyncQueueTable.queueTableFragment}
      ${AsyncQueueTable.moveToSyncQueueModalFragment}
      query Queues(
        $dates: [String!]!
        $skipPractitionerBookingWindows: Boolean!
        $asyncConsultationQueueInput: AsyncConsultationQueueInput!
        $skipAsyncConsultationQueue: Boolean!
      ) {
        brandConditions {
          id
          type
          supportsBookingWindows
          supportsAsyncConsultations
        }
        practitionerBookingWindows(input: { dates: $dates }) {
          id
          ...PractitionerBookingWindow
            @skip(if: $skipPractitionerBookingWindows)
          ...MoveToSyncQueueModalPractitionerBookingWindow
            @skip(if: $skipAsyncConsultationQueue)
        }
        asyncConsultationQueue(input: $asyncConsultationQueueInput)
          @skip(if: $skipAsyncConsultationQueue) {
          id
          totalEntryCount
          nextPageToken
          ...AsyncConsultationQueue
        }
      }
    `,
    {
      variables: {
        dates,
        skipPractitionerBookingWindows: queueType !== 'SYNC',
        asyncConsultationQueueInput,
        skipAsyncConsultationQueue: queueType !== 'ASYNC',
      },
      returnPartialData: true,
      onCompleted: ({ brandConditions, asyncConsultationQueue }) => {
        const defaultCondition = brandConditions
          ? brandConditions[0]
          : undefined;
        if (defaultCondition && !selectedProblemType) {
          setSelectedProblemType(defaultCondition.type);
        }

        const nextPageToken = asyncConsultationQueue?.nextPageToken;
        if (nextPageToken) {
          setPageDetails((prevPageDetails) => ({
            currentPageNumber: prevPageDetails.currentPageNumber,
            pageNumberToToken: new Map(prevPageDetails.pageNumberToToken).set(
              prevPageDetails.currentPageNumber + 1,
              nextPageToken,
            ),
          }));
        }
      },
    },
  );

  return (
    <div>
      <div className="flex flex-col space-y-8">
        {data?.brandConditions && (
          <ul className="flex space-x-4 font-semibold">
            {data.brandConditions
              .filter((bc) => {
                switch (queueType) {
                  case 'ASYNC':
                    return bc.supportsAsyncConsultations;
                  case 'SYNC':
                    return bc.supportsBookingWindows;
                  default:
                    return false;
                }
              })
              .map(({ type }) => {
                const isActive = type === selectedProblemType;

                let queueCount: number | undefined;
                switch (queueType) {
                  case 'ASYNC': {
                    if (isActive)
                      queueCount = data.asyncConsultationQueue?.totalEntryCount;
                    break;
                  }
                  case 'SYNC': {
                    const activeWindowForProblemType =
                      data?.practitionerBookingWindows?.find(
                        (w) =>
                          w.problemType === type &&
                          isWithinInterval(new Date(), {
                            start: new Date(w.startAt),
                            end: new Date(w.endAt),
                          }),
                      );
                    queueCount =
                      activeWindowForProblemType?.unassignedBookingsCount ?? 0;
                    break;
                  }
                  default:
                    throw new Error(`unexpected queue type: "${queueType}"`);
                }

                return (
                  <li
                    key={type}
                    className="cursor-pointer"
                    onClick={(): void => {
                      setSelectedProblemType(type);
                      history.replace({ search: '' }); // clear pageIndex search param
                    }}
                  >
                    <div>
                      <p
                        className={clsx({
                          'text-primary': isActive,
                          'text-primary-300': !isActive,
                        })}
                      >
                        {upperSnakeCaseToCapitalCase(type)}{' '}
                        {typeof queueCount === 'number' ? (
                          `(${queueCount})`
                        ) : (
                          <></>
                        )}
                      </p>
                      {isActive && (
                        <div className=" border-b-2 border-primary pt-2" />
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        )}

        {ffAsyncConsultsEnabled && (
          <fieldset className="flex space-x-2">
            <label>
              <div
                className={`border border-r border-slate-600 py-1 p-2 rounded-full ${
                  queueType === 'SYNC' ? 'text-white bg-primary' : ''
                }`}
              >
                Sync: phone call
              </div>
              <input
                type="radio"
                name="queue-type"
                value="SYNC"
                checked={queueType === 'SYNC'}
                onChange={() => setQueueType('SYNC')}
                className="hidden"
              />
            </label>
            <label>
              <div
                className={`border border-r border-slate-600 py-1 px-2 rounded-full ${
                  queueType === 'ASYNC' ? 'text-white bg-primary' : ''
                }`}
              >
                Async: text based
              </div>
              <input
                type="radio"
                name="queue-type"
                value="ASYNC"
                checked={queueType === 'ASYNC'}
                onChange={() => setQueueType('ASYNC')}
                className="hidden"
              />
            </label>
          </fieldset>
        )}
      </div>
      <div className="border-t border-gray-300 my-4" />

      {queueType === 'SYNC' &&
        selectedProblemType &&
        (data?.practitionerBookingWindows ? (
          <SyncQueues
            refetch={refetch}
            problemType={selectedProblemType}
            practitionerBookingWindows={data?.practitionerBookingWindows}
          />
        ) : (
          <div className="flex justify-center text-lg">
            <Loading />
          </div>
        ))}
      {queueType === 'ASYNC' && (
        <div className="space-y-2">
          <form className="flex justify-between space-x-2">
            <div className="flex-grow">
              <Dropdown
                name="consultationStages"
                label="Consult Type"
                isMulti={true}
                options={typeFilterOptions}
                control={asyncFiltersForm.control}
              />
            </div>
            <div className="flex-grow">
              <Dropdown
                name="reviewReasons"
                label="Review Reasons"
                isMulti={true}
                options={reasonOptions}
                control={asyncFiltersForm.control}
              />
            </div>
            <div className="flex-grow">
              <DoctorSelect
                name="preferredPractitioners"
                label="Prac Preference"
                isMulti={true}
                hasAllOption={false}
                showAllDoctors={true}
                control={asyncFiltersForm.control}
              />
            </div>
            <div className="flex-grow">
              <Dropdown
                name="priority"
                label="Priority"
                options={priorityFilterOptions}
                control={asyncFiltersForm.control}
              />
            </div>
          </form>
          {data?.asyncConsultationQueue?.entries ? (
            <div>
              <AsyncQueueTable
                bookingWindows={data.practitionerBookingWindows ?? []}
                rowData={data.asyncConsultationQueue.entries}
              />
              <div className="w-full flex justify-end pt-2">
                <button
                  className="w-10 h-10 ml-2 first:ml-0 border rounded text-gray-600 border-gray-600 hover:bg-gray-400 disabled:text-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed"
                  onClick={() => {
                    client.cache.evict({
                      fieldName: 'asyncConsultationQueue',
                    });
                    client.cache.gc();

                    setPageDetails((prevPageDetails) => ({
                      currentPageNumber: prevPageDetails.currentPageNumber - 1,
                      pageNumberToToken: new Map(
                        prevPageDetails.pageNumberToToken,
                      ),
                    }));
                  }}
                  disabled={
                    pageDetails.pageNumberToToken.get(
                      pageDetails.currentPageNumber - 1,
                    ) === undefined
                  }
                >
                  <FaChevronLeft className="mx-auto stroke-current" />
                </button>
                <div className="px-2 flex justify-center items-center">
                  Page {pageDetails.currentPageNumber} of{' '}
                  {Math.ceil(
                    data.asyncConsultationQueue.totalEntryCount / tablePageSize,
                  )}
                </div>
                <button
                  className="w-10 h-10 ml-2 first:ml-0 border rounded text-gray-600 border-gray-600 hover:bg-gray-400 disabled:text-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed"
                  onClick={() => {
                    client.cache.evict({
                      fieldName: 'asyncConsultationQueue',
                    });
                    client.cache.gc();
                    setPageDetails((prevPageDetails) => ({
                      currentPageNumber: prevPageDetails.currentPageNumber + 1,
                      pageNumberToToken: new Map(
                        prevPageDetails.pageNumberToToken,
                      ),
                    }));
                  }}
                  disabled={
                    pageDetails.pageNumberToToken.get(
                      pageDetails.currentPageNumber + 1,
                    ) === undefined
                  }
                >
                  <FaChevronRight className="mx-auto stroke-current" />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center text-lg">
              <Loading />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Queues;

import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';

import LegacyDiscount from 'pages/legacy-discounts';
import LegacyDiscounts from 'pages/legacy-discounts/discount';
import CreateLegacyDiscount from 'pages/legacy-discounts/create-discount';
import { routes } from 'utils/routes';
import { CouponCampaign } from 'pages/coupon-campaigns/campaign';
import { CouponCampaignSummaries } from 'pages/coupon-campaigns/summaries';

export const Discounts = (): React.ReactElement => {
  const location = useLocation();
  const ccEnabled = useFeatureFlagClient().getBoolean(
    'ff_enable_coupon_campaigns',
  );

  const tabs = [
    {
      name: 'Legacy Discounts',
      route: routes.legacyDiscounts,
      selected: location.pathname.startsWith(routes.legacyDiscounts),
    },
    {
      name: 'Coupon Campaigns',
      route: routes.couponCampaignSummaries,
      selected: location.pathname.startsWith(routes.couponCampaignSummaries),
    },
  ] as const;

  return (
    <div>
      {ccEnabled && (
        <div className="flex flex-row space-x-4 border-b-2 mb-8">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.route}
                className={`border-b-2  flex justify-center items-center ${
                  tab.selected ? 'border-primary-500' : 'border-gray-500'
                }`}
              >
                <Link
                  to={tab.route}
                  className={`font-medium px-4 py-2 ${
                    tab.selected ? 'text-primary-500' : 'text-gray-500'
                  }`}
                >
                  {tab.name}
                </Link>
              </div>
            );
          })}
        </div>
      )}
      <Switch>
        <Redirect
          exact
          from="/discounts"
          to={
            ccEnabled ? routes.couponCampaignSummaries : routes.legacyDiscounts
          }
        />
        <Route path={routes.createLegacyDiscount}>
          <CreateLegacyDiscount />
        </Route>
        <Route path={routes.legacyDiscount}>
          <LegacyDiscount />
        </Route>
        <Route path={routes.legacyDiscounts}>
          <LegacyDiscounts />
        </Route>
        <Route path={routes.couponCampaign}>
          <CouponCampaign />
        </Route>
        <Route path={routes.couponCampaignSummaries}>
          <CouponCampaignSummaries />
        </Route>
      </Switch>
    </div>
  );
};

import { Button } from 'components/button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'utils/routes';
import { gql, useQuery } from '@apollo/client';
import {
  CliniciansTableQuery,
  CliniciansTableQueryVariables,
} from 'graphql/types';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePageIndex,
  useSelectablePaginatingSortingTable,
} from 'components/table';
import React, { useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { formatDateAndTime } from 'utils/misc';
import { DeleteManyUsersButton } from 'components/delete-many-users-button';
import { Tag } from 'components/tag';
import { getClinicianTypeLabel } from '../clinician/clinician-type-dropdown';
import { ActivityModal } from './activity-modal';
import { useChatContext } from 'stream-chat-react';
import { useForm } from 'react-hook-form';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { Dropdown } from 'components/dropdown';
import { z } from 'zod';
import { SearchBar } from 'components/search-bar';

type Clinician = NonNullable<
  NonNullable<CliniciansTableQuery['practitioners']>['practitioners']
>[number];

const tablePageSize = 10;
const activationStatusEnum = z.enum(['ACTIVE', 'INACTIVE', 'DEACTIVATED']);
const queueEligibilityEnum = z.enum(['ASYNC', 'SYNC']);

const AvailabilityCell = ({
  value,
}: CellProps<Clinician, Clinician>): React.ReactElement => {
  const { color, label } = useMemo<{
    color: 'red' | 'green' | 'orange';
    label: string;
  }>(() => {
    const isDeactivated = !!value.deactivatedAt;
    const isAvailable = !!value.isAvailable;

    if (isDeactivated) {
      return { color: 'red', label: 'Deactivated' };
    } else if (isAvailable) {
      return { color: 'green', label: 'Available' };
    } else {
      return { color: 'orange', label: 'Unavailable' };
    }
  }, [value]);

  return (
    <div className="mt-1">
      <div className="inline-block mr-1 mb-1">
        <Tag size="small" color={color}>
          {label}
        </Tag>
      </div>
    </div>
  );
};

const consultationsPendingCell = (
  cell: CellProps<Clinician, Clinician['filteredConsultations']>,
): React.ReactElement => <div>{cell.value.length}</div>;

const scriptsPendingCell = (
  cell: CellProps<Clinician, Clinician['scriptsPending']>,
): React.ReactElement => <div>{cell.value}</div>;

const oldestUnsignedScriptDateCell = (
  cell: CellProps<Clinician, Clinician['oldestUnsignedScriptDate']>,
): React.ReactElement => (
  <div>{cell.value ? formatDateAndTime(cell.value) : ''}</div>
);

const columns: Column<Clinician>[] = [
  {
    Header: 'Name',
    accessor: 'firstName',
    Cell: (c) => <div>{c.row.original.clinicianName}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Practitioner Type',
    accessor: 'provider',
    Cell: (c) => {
      const clinicianType = c.row.original.provider?.clinicianType;
      if (!clinicianType) {
        return null;
      }
      return <div>{getClinicianTypeLabel(clinicianType)}</div>;
    },
    className: 'w-1/12',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: (row) => row,
    Cell: AvailabilityCell,
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Queues',
    Cell: ({ row }) => {
      if (row.original.approvedForAsyncConsultations) {
        return 'Sync & Async';
      }
      return 'Sync only';
    },
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/6',
  },
  {
    Header: 'Consultations Pending',
    accessor: 'filteredConsultations',
    Cell: consultationsPendingCell,
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Scripts Pending',
    accessor: 'scriptsPending',
    Cell: scriptsPendingCell,
    className: 'w-1/6',
    disableSortBy: true,
  },
  {
    Header: 'Oldest Script',
    accessor: 'oldestUnsignedScriptDate',
    Cell: oldestUnsignedScriptDateCell,
    className: 'w-1/6',
  },
];

const Clinicians = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchSearchParam = searchParams.get('search');
  const queuesSearchParam = searchParams.get('queues');
  const statusSearchParam = searchParams.get('status');
  const chatContext = useChatContext();
  const pageIndex = usePageIndex();

  const activationStatusParseResult =
    activationStatusEnum.safeParse(statusSearchParam);
  const queueEligibiltiyParseResult =
    queueEligibilityEnum.safeParse(queuesSearchParam);
  const { data, loading, refetch } = useQuery<
    CliniciansTableQuery,
    CliniciansTableQueryVariables
  >(
    gql`
      query CliniciansTable($input: PractitionersInput!) {
        practitioners(input: $input) {
          practitioners {
            id
            createdAt
            isAvailable
            deactivatedAt
            email
            clinicianName
            firstName
            lastName
            fullName
            provider {
              id
              clinicianType
            }
            filteredConsultations: doctorConsultations(
              where: { status: { equals: DOCTOR_ASSIGNED } }
            ) {
              id
            }
            scriptsPending
            oldestUnsignedScriptDate
            approvedForAsyncConsultations
          }
          totalCount
        }
      }
    `,
    {
      variables: {
        input: {
          pageRequest: {
            page: pageIndex + 1,
            pageSize: tablePageSize,
          },
          filterInput: {
            activationStatus: activationStatusParseResult.success
              ? activationStatusParseResult.data
              : undefined,
            searchTerm: searchSearchParam || undefined,
            queueEligibility: queueEligibiltiyParseResult.success
              ? queueEligibiltiyParseResult.data
              : undefined,
          },
        },
      },
    },
  );

  const clinicians = React.useMemo(
    () => data?.practitioners?.practitioners ?? [],
    [data],
  );
  const total = data?.practitioners?.practitioners.length ?? 0;
  const pageNumber = Math.ceil(
    (data?.practitioners?.totalCount ?? 1) / tablePageSize,
  );
  const cliniciansTable = useSelectablePaginatingSortingTable({
    columns,
    data: clinicians,
    pageNumber,
    pageIndex,
  });
  const selectedRows = cliniciansTable.selectedFlatRows;
  const [activityModalOpen, setActivityModalOpen] = useState<boolean>(false);

  const filterForm = useForm<{
    status: string;
    search: string;
    queues: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      status: activationStatusParseResult.success
        ? activationStatusParseResult.data
        : undefined,
      search: searchSearchParam || undefined,
      queues: queueEligibiltiyParseResult.success
        ? queueEligibiltiyParseResult.data
        : undefined,
    },
  });

  const searchWatcher = filterForm.watch().search;
  const statusWatcher = filterForm.watch().status;
  const queuesWatcher = filterForm.watch().queues;

  if (
    statusWatcher !== statusSearchParam ||
    (statusWatcher !== '' && statusSearchParam === null)
  ) {
    searchParams.delete('pageIndex');

    if (statusWatcher) {
      searchParams.delete('pageIndex');

      searchParams.set('status', statusWatcher);
    } else searchParams.delete('status');
  }

  if (
    queuesWatcher !== queuesSearchParam ||
    (queuesWatcher !== '' && queuesSearchParam === null)
  ) {
    if (queuesWatcher) {
      searchParams.delete('pageIndex');
      searchParams.set('queues', queuesWatcher);
    } else searchParams.delete('queues');
  }

  if (searchParams.toString() !== location.search.slice(1)) {
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }

  useDebouncedEffect(
    () => {
      searchParams.delete('pageIndex');
      if (searchWatcher) searchParams.set('search', searchWatcher);
      else searchParams.delete('search');

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    500,
    [searchWatcher],
  );

  return (
    <div>
      <div className="flex flex-row w-1/2 justify-end float-right">
        {!!selectedRows.length && (
          <div className="w-auto pr-4">
            <DeleteManyUsersButton
              table="User"
              ids={selectedRows.map((row) => row.original.id)}
              onDeleted={refetch}
            />
          </div>
        )}
        <div className="flex items-center space-x-2">
          {chatContext.client && (
            <Button
              fullWidth
              variant="outline"
              onClick={() => setActivityModalOpen(true)}
            >
              View activity
            </Button>
          )}
          <Link to={routes.createClinician}>
            <Button fullWidth variant="solid">
              Create&nbsp;New
            </Button>
          </Link>
        </div>
      </div>
      <div className="pt-16">
        <form>
          <div className="flex space-x-2 mb-8">
            <div className="w-2/5">
              <SearchBar
                ref={filterForm.register}
                name="search"
                label="Search"
                placeholder="Search by Email, Name or ID"
              />
            </div>
            <div className="w-1/5 pt-2">
              <Dropdown
                control={filterForm.control}
                name="status"
                label="Status"
                defaultValue=""
                options={[
                  { value: '', label: 'All' },
                  { value: 'ACTIVE', label: 'Available' },
                  { value: 'INACTIVE', label: 'Unavailable' },
                  { value: 'DEACTIVATED', label: 'Deactivated' },
                ]}
              />
            </div>
            <div className="w-1/5 pt-2">
              <Dropdown
                control={filterForm.control}
                name="queues"
                label="Queues"
                defaultValue=""
                options={[
                  { value: '', label: 'Any' },
                  { value: 'SYNC', label: 'Sync only' },
                ]}
              />
            </div>
          </div>
        </form>
      </div>
      <div
        className={clsx({ 'visually-hidden': !cliniciansTable.page.length })}
      >
        <Table tableInstance={cliniciansTable}>
          <TableHead />
          <TableBody>
            <React.Fragment>
              {cliniciansTable.page?.map((row) => {
                cliniciansTable.prepareRow(row);
                return (
                  <TableRow row={row} key={row.id}>
                    <React.Fragment>
                      {row.cells.map((cell) => (
                        <TableCell
                          key={`${cell.row.original.id}-${cell.column.id}`}
                          cell={cell}
                          onClick={(): void => {
                            if (cell.column.id !== 'selection') {
                              history.push(
                                `${routes.clinicians}/${cell.row.original.id}`,
                              );
                            }
                          }}
                        />
                      ))}
                    </React.Fragment>
                  </TableRow>
                );
              })}
            </React.Fragment>
          </TableBody>
        </Table>
        <Pagination total={total} tableInstance={cliniciansTable} />
      </div>
      {!cliniciansTable.page.length && !loading && (
        <div className="text-center font-medium pt-8">
          No practitioners found
        </div>
      )}
      {loading && (
        <div className="flex justify-center text-lg">
          <Loading />
        </div>
      )}
      {activityModalOpen && (
        <ActivityModal onClose={() => setActivityModalOpen(false)} />
      )}
    </div>
  );
};

export default Clinicians;

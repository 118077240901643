import { ApolloQueryResult, gql, useApolloClient } from '@apollo/client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import {
  addYears,
  format,
  max as dfMax,
  min as dfMin,
  differenceInCalendarDays,
  addMinutes,
} from 'date-fns';
import { formatDate } from '@eucalyptusvc/lib-localization';
import {
  AdvancePurchaseSyncGroupMutation,
  AdvancePurchaseSyncGroupMutationVariables,
  DelayPurchaseSyncGroupMutation,
  DelayPurchaseSyncGroupMutationVariables,
  ReschedulePurchaseSyncGroupFragment,
  ValidateReschedulePurchaseSyncGroupQuery,
  ValidateReschedulePurchaseSyncGroupQueryVariables,
} from 'graphql/types';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { config } from 'config';
import { brandFromEngineBrand } from '@eucalyptusvc/lib-adapters';
import clsx from 'clsx';
import { Input } from 'components/input';

interface Props {
  syncGroup: ReschedulePurchaseSyncGroupFragment;
  immediate?: true;
  onClose: () => void;
}

const delayPurchaseSyncGroupDocument = gql`
  mutation delayPurchaseSyncGroup($input: DelayPurchaseSyncGroupInput!) {
    delayPurchaseSyncGroup(input: $input) {
      purchaseSyncGroup {
        id
        nextOrderDueAt
        actions {
          id
        }
        sequenceContexts {
          id
          nextMoveDue
          nextOrderDue
          mermaid
        }
      }
    }
  }
`;

const advancePurchaseSyncGroup = gql`
  mutation advancePurchaseSyncGroup($input: AdvancePurchaseSyncGroupInput!) {
    advancePurchaseSyncGroup(input: $input) {
      purchaseSyncGroup {
        id
        nextOrderDueAt
        actions {
          id
        }
        sequenceContexts {
          id
          nextMoveDue
          nextOrderDue
          mermaid
        }
      }
    }
  }
`;

function toInputString(date: Date): string {
  return format(date, `yyyy-MM-dd'T'HH:mm`);
}

function getBounds(
  canDelay: boolean,
  canAdvance: boolean,
  nextOrderDate: Date,
) {
  let min = addMinutes(new Date(), 15);
  if (!canAdvance) {
    min = dfMax([min, nextOrderDate]);
  }

  let max = addYears(new Date(), 1);
  if (!canDelay) {
    max = dfMin([max, nextOrderDate]);
  }

  const formattedMin = formatDate(
    brandFromEngineBrand(config.brand),
    new Date(min),
    { dateStyle: 'medium', timeStyle: 'full' },
  );

  const formattedMax = formatDate(
    brandFromEngineBrand(config.brand),
    new Date(max),
    { dateStyle: 'medium', timeStyle: 'full' },
  );

  return {
    min,
    formattedMin,
    max,
    formattedMax,
  };
}

function ReschedulePurchaseSyncGroup(props: Props) {
  const validationPromise =
    useRef<
      Promise<ApolloQueryResult<ValidateReschedulePurchaseSyncGroupQuery>>
    >();

  const apollo = useApolloClient();

  // Set the "immediate" time to 30 minutes from now
  // to allow some buffer for the 15 minute min validation.
  let defaultNextOrderAt = addMinutes(new Date(), 30);
  if (props.syncGroup.nextOrderDueAt && !props.immediate) {
    defaultNextOrderAt = new Date(props.syncGroup.nextOrderDueAt);
  }

  const form = useForm<{ nextOrderDueAt: string; reason: string }>({
    mode: 'onChange',
    defaultValues: {
      nextOrderDueAt: toInputString(defaultNextOrderAt),
    },
  });

  let delayAction: { id: string } | undefined;
  let advanceAction: { id: string } | undefined;
  for (const a of props.syncGroup.actions ?? []) {
    if (a.__typename === 'DelayPurchaseSyncGroupAction') {
      delayAction = a;
    } else if (a.__typename === 'AdvancePurchaseSyncGroupAction') {
      advanceAction = a;
    }
  }

  let bounds;
  if (props.syncGroup.nextOrderDueAt) {
    bounds = getBounds(
      !!delayAction,
      !!advanceAction,
      new Date(props.syncGroup.nextOrderDueAt),
    );
  }

  const reschedulable = !!props.syncGroup.actions?.some(
    (a) =>
      a.__typename === 'DelayPurchaseSyncGroupAction' ||
      a.__typename === 'AdvancePurchaseSyncGroupAction',
  );

  const reschedule = form.handleSubmit(async (fields): Promise<void> => {
    if (!props.syncGroup.nextOrderDueAt) {
      return;
    }

    try {
      if (
        new Date(fields.nextOrderDueAt) >
        new Date(props.syncGroup.nextOrderDueAt)
      ) {
        await apollo.mutate<
          DelayPurchaseSyncGroupMutation,
          DelayPurchaseSyncGroupMutationVariables
        >({
          mutation: delayPurchaseSyncGroupDocument,
          variables: {
            input: {
              targetTime: new Date(fields.nextOrderDueAt).toISOString(),
              purchaseSyncGroupId: props.syncGroup.id,
              reason: fields.reason,
            },
          },
        });
      } else {
        await apollo.mutate<
          AdvancePurchaseSyncGroupMutation,
          AdvancePurchaseSyncGroupMutationVariables
        >({
          mutation: advancePurchaseSyncGroup,
          variables: {
            input: {
              targetTime: new Date(fields.nextOrderDueAt).toISOString(),
              purchaseSyncGroupId: props.syncGroup.id,
              reason: fields.reason,
            },
          },
        });
      }
    } finally {
      props.onClose();
    }
  });

  const pluralisedSequence =
    (props.syncGroup.sequenceContexts?.length ?? 0) > 1
      ? 'sequences'
      : 'sequence';

  return (
    <Modal show onClose={props.onClose}>
      <form
        onSubmit={reschedule}
        className="bg-white shadow overflow-hidden rounded px-4 py-5 max-w-lg mx-auto text-gray-800"
      >
        <h3 className="text-lg leading-6 font-medium mb-3">
          Reschedule next order for {pluralisedSequence}
        </h3>
        {reschedulable ? (
          <p className="mb-3">
            The {pluralisedSequence} will be scheduled so that the next order
            will happen at{' '}
            <span className="font-medium">
              {formatDate(
                brandFromEngineBrand(config.brand),
                new Date(form.watch('nextOrderDueAt')),
                {
                  dateStyle: 'medium',
                  timeStyle: 'full',
                },
              )}
            </span>
            .
          </p>
        ) : (
          <p className="mb-3">
            <span className="capitalize">{pluralisedSequence}</span> cannot be
            rescheduled. This may be due to no upcoming orders or the sequences
            being inactive.
          </p>
        )}
        <input
          className={clsx(
            'text-lg border border-gray-400 hover:border-gray-500 rounded p-2 w-full',
            !reschedulable && 'hidden',
          )}
          ref={form.register({
            required: true,
            validate: async (val) => {
              if (!val) {
                return 'No date specified';
              }

              if (!validationPromise.current) {
                validationPromise.current = apollo
                  .query<
                    ValidateReschedulePurchaseSyncGroupQuery,
                    ValidateReschedulePurchaseSyncGroupQueryVariables
                  >({
                    fetchPolicy: 'network-only',
                    query: gql`
                      query ValidateReschedulePurchaseSyncGroup($id: ID!) {
                        purchaseSyncGroup(id: $id) {
                          id
                          nextOrderDueAt
                          actions {
                            id
                          }
                        }
                      }
                    `,
                    variables: {
                      id: props.syncGroup.id,
                    },
                  })
                  .then(
                    (resp) =>
                      new Promise((resolve) =>
                        setTimeout(() => resolve(resp), 1000),
                      ),
                  );
              }

              const resp = await validationPromise.current;
              validationPromise.current = undefined;

              let nextOrderDue;
              let canDelay = false;
              let canAdvance = false;
              if (resp.data.purchaseSyncGroup) {
                nextOrderDue = resp.data.purchaseSyncGroup.nextOrderDueAt;
                for (const a of resp.data.purchaseSyncGroup.actions ?? []) {
                  if (a.__typename === 'DelayPurchaseSyncGroupAction') {
                    canDelay = true;
                  } else if (
                    a.__typename === 'AdvancePurchaseSyncGroupAction'
                  ) {
                    canAdvance = true;
                  }
                }
              }

              if (!nextOrderDue) {
                return 'No next order due to be rescheduled';
              }

              const bounds = getBounds(
                canDelay,
                canAdvance,
                new Date(nextOrderDue),
              );

              if (new Date(val) < bounds.min) {
                return `The next order must be scheduled after ${bounds.formattedMin}.`;
              }

              if (new Date(val) > bounds.max) {
                return `The next order must be scheduled before ${bounds.formattedMax}.`;
              }

              return true;
            },
          })}
          type="datetime-local"
          name="nextOrderDueAt"
          min={bounds ? toInputString(bounds.min) : undefined}
          max={bounds ? toInputString(bounds.max) : undefined}
          disabled={!reschedulable}
        />
        <div className={clsx('mt-2', !reschedulable && 'hidden')}>
          <Input
            name="reason"
            placeholder="Reason"
            ref={form.register({ required: 'Reason is required.' })}
          />
        </div>
        <div
          className={clsx(
            'mt-1.5 text-sm text-red-700',
            Object.keys(form.errors).length === 0 && 'hidden',
          )}
        >
          {form.errors.nextOrderDueAt?.message ?? form.errors.reason?.message}
        </div>
        {(() => {
          if (!form.formState.isDirty || !props.syncGroup.nextOrderDueAt) {
            return null;
          }

          const daysDiff = differenceInCalendarDays(
            new Date(form.watch('nextOrderDueAt')),
            new Date(props.syncGroup.nextOrderDueAt),
          );

          if (daysDiff === 0) {
            return null;
          }

          return (
            <p className="mt-3">
              The {pluralisedSequence} will be moved{' '}
              <span className="font-semibold">
                {Math.abs(daysDiff)} day{Math.abs(daysDiff) === 1 ? '' : 's'}{' '}
                {daysDiff > 0 ? 'later' : 'sooner'}
              </span>
              .
            </p>
          );
        })()}
        <div className="flex gap-2 mt-8 justify-end">
          <Button variant="outline" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            disabled={!form.formState.isValid || form.formState.isValidating}
            color="success"
            type="submit"
            loading={form.formState.isSubmitting || form.formState.isValidating}
          >
            Reschedule
          </Button>
        </div>
      </form>
    </Modal>
  );
}

ReschedulePurchaseSyncGroup.fragment = gql`
  fragment ReschedulePurchaseSyncGroup on PurchaseSyncGroup {
    id
    nextOrderDueAt
    sequenceContexts {
      id
    }
    actions {
      id
    }
  }
`;

export { ReschedulePurchaseSyncGroup };

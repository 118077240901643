import { useMutation } from '@apollo/client';
import {
  UpsertDiscountCodeMutation,
  UpsertDiscountCodeMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { ReactElement, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import {
  DiscountCodeForm,
  DiscountCodeFormFields,
  upsertDiscountCodeMutation,
  mapDiscountCodeFormFieldsToGraphQLUpsertData,
} from 'components/discount-form';

const CreateDiscount = (): ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();

  const [upsertDiscountCode, { loading }] = useMutation<
    UpsertDiscountCodeMutation,
    UpsertDiscountCodeMutationVariables
  >(upsertDiscountCodeMutation, {
    onCompleted: ({ upsertDiscountCode: data }) => {
      showNotification({
        type: 'success',
        message: 'Discount code created successfully',
      });
      history.push(`${routes.legacyDiscounts}/${data?.discountCode?.id}`);
    },
    onError: () => {
      showNotification({
        type: 'error',
        message: 'Error creating discount code',
      });
    },
  });

  const handleSubmit = useMemo(
    () =>
      async (fields: DiscountCodeFormFields): Promise<void> => {
        await upsertDiscountCode({
          variables: {
            input: mapDiscountCodeFormFieldsToGraphQLUpsertData(fields),
          },
        });
      },
    [upsertDiscountCode],
  );

  return (
    <div className="container">
      <DiscountCodeForm
        type="create"
        onSubmit={handleSubmit}
        submitting={loading}
      />
    </div>
  );
};

export default CreateDiscount;

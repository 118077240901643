import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import {
  NoPickupQueueQuery,
  NoPickupQueueRowFragment,
  ProblemType,
} from 'graphql/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { routes } from 'utils/routes';
import { Button } from 'components/button';
import { Tag } from 'components/tag';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { formatPatientName, getConsultationStageColor } from 'utils/misc';
import { AssignModal } from 'components/assign-modal';
import { Modal } from 'components/modal';

export const adminQueueColumns = (
  triggerAssignModal: React.Dispatch<React.SetStateAction<boolean>>,
  setConsultationId: React.Dispatch<React.SetStateAction<string>>,
): Column<NoPickupQueueRowFragment>[] => {
  const cols: Column<NoPickupQueueRowFragment>[] = [
    {
      Header: 'Patient',
      Cell: (c) => {
        const customer = c.row.original.patient;
        return customer ? (
          <div>
            <div>{formatPatientName(customer)}</div>
            <div className="text-gray-600">{customer.id}</div>
          </div>
        ) : (
          <div />
        );
      },
    },
    {
      Header: 'Status',
      disableSortBy: true,
      Cell: (c) => (
        <div>
          <p>{c.row.original.consultation?.status ?? 'N/A'}</p>
        </div>
      ),
    },
    {
      Header: 'Problem Type',
      Cell: (c) => (
        <ProblemTypeTag problemType={c.row.original.consultation?.type} />
      ),
      disableSortBy: true,
      className: 'w-24',
    },
    {
      Header: 'Stage',
      disableSortBy: true,
      Cell: (c) => {
        if (!c.row.original.consultation) {
          return <div>N/A</div>;
        }
        return (
          <Tag
            size="small"
            color={getConsultationStageColor(c.row.original.consultation.stage)}
          >
            {c.row.original.consultation.stage}
          </Tag>
        );
      },
      className: 'w-24',
    },
    {
      Header: 'Call window',
      Cell: 'No pickup',
    },
    {
      Header: 'Prac. Assigned',
      Cell: (c) => {
        if (!c.row.original.consultation) {
          return <div>N/A</div>;
        }
        if (c.row.original.consultation.status === 'AWAITING_DOCTOR') {
          return (
            <div className="w-24">
              <Button
                fullWidth
                variant="outline"
                onClick={(): void => {
                  setConsultationId(c.row.original.consultation?.id || '');
                  triggerAssignModal(true);
                }}
              >
                Assign
              </Button>
            </div>
          );
        }
        return (
          <div>
            {c.row.original.consultation.doctor
              ? c.row.original.consultation.doctor.clinicianName
              : `N/A`}
          </div>
        );
      },
      id: 'assign-practitioner',
      disableSortBy: true,
    },
  ];

  return cols;
};

type NoPickupQueueTableProps = {
  problemType: ProblemType;
};

const NoPickupQueueTable: React.FC<NoPickupQueueTableProps> = ({
  problemType,
}) => {
  const history = useHistory();
  const [showAssignModal, setShowAssignModal] = React.useState(false);
  const [consultationId, setConsultationId] = React.useState('');

  const { data, refetch } = useSuspenseQuery<NoPickupQueueQuery>(gql`
    query NoPickupQueue {
      adminQueue {
        ...NoPickupQueueRow
      }
    }

    fragment NoPickupQueueRow on AdminQueueEntry {
      id
      patient {
        id
        fullName
      }
      consultation {
        id
        status
        type
        stage
        doctor {
          id
          clinicianName
        }
      }
    }
  `);

  const bookings =
    data.adminQueue?.filter((q) => q.consultation?.type === problemType) ?? [];

  const noPickupTableColumns = React.useMemo(
    () => adminQueueColumns(setShowAssignModal, setConsultationId),
    [setConsultationId, setShowAssignModal],
  );

  const noPickupTableInstance = usePaginatingSortingTable({
    columns: noPickupTableColumns,
    data: bookings,
    pageNumber: 1,
    disableSortBy: true,
  });

  return (
    <>
      <Table tableInstance={noPickupTableInstance}>
        <TableHead />
        <TableBody>
          {noPickupTableInstance.page?.map((row) => {
            noPickupTableInstance.prepareRow(row);
            return (
              <TableRow row={row} key={row.id}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={`${cell.column.id}-${cell.row.original.id}`}
                    cell={cell}
                    onClick={(): void => {
                      if (cell.column.id !== 'assign-practitioner') {
                        history.push(
                          `${routes.consultations}/${cell.row.original.consultation?.id}`,
                        );
                      }
                    }}
                  />
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Modal
        show={showAssignModal}
        isAutoOverflow={false}
        onClose={(): void => {
          setShowAssignModal(false);
        }}
      >
        <AssignModal
          consultationIds={[consultationId]}
          onAssigned={async (): Promise<void> => {
            setShowAssignModal(false);
            await refetch();
          }}
        />
      </Modal>
      {bookings.length === 0 && (
        <div className="text-center font-medium pt-8 text-gray-600">
          No bookings found
        </div>
      )}
    </>
  );
};

export default NoPickupQueueTable;

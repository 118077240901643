import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown';
import { Modal } from 'components/modal';
import {
  ApplySupportDiscountFragment,
  ApplySupportDiscountMutation,
  ApplySupportDiscountMutationVariables,
  ApplySupportDiscountReasonsFragment,
} from 'graphql/types';

import { useNotifications } from '../../notifications';

interface Props {
  syncGroup: ApplySupportDiscountFragment;
  reasons: ApplySupportDiscountReasonsFragment[];
  onClose: () => void;
}

const applySupportDiscountMutation = gql`
  mutation ApplySupportDiscount(
    $input: ApplySupportDiscountToPurchaseSyncGroupInput!
  ) {
    applySupportDiscountToPurchaseSyncGroup(input: $input) {
      purchaseSyncGroup {
        id
        timeline {
          id
          discountAmount
          discounts {
            id
            amount
            name
          }
        }
      }
    }
  }
`;

function ApplySupportDiscount(props: Props) {
  const showNotification = useNotifications();

  const form = useForm<{
    reasonId: string;
    discountId: string;
  }>({
    mode: 'all',
    defaultValues: {},
  });

  const [apply, { loading }] = useMutation<
    ApplySupportDiscountMutation,
    ApplySupportDiscountMutationVariables
  >(applySupportDiscountMutation, {
    onCompleted: () => {
      showNotification({
        type: 'success',
        message: 'Discount applied successfully',
      });
      props.onClose();
    },
    onError: () => {
      showNotification({
        type: 'error',
        message: 'Unable to apply discount',
      });
    },
  });

  const onSubmit = form.handleSubmit(async (fields) => {
    await apply({
      variables: {
        input: {
          purchaseSyncGroupId: props.syncGroup.id,
          discountId: fields.discountId,
          reasonId: fields.reasonId,
        },
      },
    });
  });

  const supportDiscounts = [...(props.syncGroup.supportDiscounts ?? [])].sort(
    (a, b) => {
      if (!a.applied && b.applied) {
        return -1;
      }
      if (a.applied && !b.applied) {
        return 1;
      }
      return 0;
    },
  );

  return (
    <Modal show onClose={props.onClose} isAutoOverflow={false}>
      <form
        onSubmit={onSubmit}
        className="bg-white shadow rounded px-4 py-5 max-w-lg mx-auto text-gray-800"
      >
        <h3 className="text-lg leading-6 font-medium mb-6">Apply Discount</h3>

        {supportDiscounts.length < 1 ? (
          <p>
            No support discounts are applicable to this Purchase Sync Group.
          </p>
        ) : (
          <div className="space-y-4">
            <Dropdown
              name="reasonId"
              label="Reason"
              control={form.control}
              options={props.reasons.map((reason) => ({
                label: reason.reasonCopy,
                value: reason.id,
              }))}
              errorMessage={form.errors.reasonId?.message}
              rules={{
                required: {
                  value: true,
                  message: 'A reason must be provided',
                },
              }}
            />
            <Dropdown
              name="discountId"
              label="Discount"
              control={form.control}
              options={supportDiscounts.map((sd) => ({
                label: `${sd.name}${sd.applied ? ' (already applied)' : ''}`,
                value: sd.discountId,
                isDisabled: sd.applied,
              }))}
              errorMessage={form.errors.discountId?.message}
              rules={{
                required: {
                  value: true,
                  message: 'A discount must be selected',
                },
              }}
            />
          </div>
        )}
        <div className="flex gap-2 mt-6 justify-end">
          <Button variant="outline" onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            disabled={!form.formState.isValid || supportDiscounts.length < 1}
            color="success"
            type="submit"
            loading={loading}
          >
            Apply
          </Button>
        </div>
      </form>
    </Modal>
  );
}

ApplySupportDiscount.fragment = gql`
  fragment ApplySupportDiscount on PurchaseSyncGroup {
    id
    status
    supportDiscounts {
      id
      name
      discountId
      applied
    }
  }
`;

ApplySupportDiscount.reasonsFragment = gql`
  fragment ApplySupportDiscountReasons on SupportDiscountReason {
    id
    reasonCopy
  }
`;

export { ApplySupportDiscount };

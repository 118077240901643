import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal } from './modal';
import { useHistory } from 'react-router';
import { gql, useLazyQuery } from '@apollo/client';
import { BigSearchQuery, BigSearchQueryVariables } from 'graphql/types';
import { buildRoute, routes } from 'utils/routes';
import {
  FaBook,
  FaBoxOpen,
  FaCartPlus,
  FaClipboardList,
  FaDollarSign,
  FaFilePrescription,
  FaMoneyBillWave,
  FaPhotoVideo,
  FaPrescriptionBottleAlt,
  FaProjectDiagram,
  FaReceipt,
  FaRegListAlt,
  FaSchool,
  FaScroll,
  FaSearch,
  FaShoppingBag,
  FaShoppingCart,
  FaSpinner,
  FaStethoscope,
  FaStream,
  FaSyringe,
  FaUser,
  FaVideo,
  FaWeight,
  FaWpforms,
} from 'react-icons/fa';
import { validate, version } from 'uuid';
import { isCuid } from 'cuid';
import { IconType } from 'react-icons/lib';
import { useHasPermissions } from './permissions';

type Page = {
  type: 'page';
  label: string;
  onSelect: () => void;
  Icon?: IconType;
};

const startingPages: (Omit<Page, 'onSelect'> & {
  url: string;
})[] = [
  {
    type: 'page',
    label: 'Consultations',
    url: routes.consultations,
    Icon: FaClipboardList,
  },
  {
    type: 'page',
    label: 'Patients',
    url: routes.customers,
    Icon: FaUser,
  },
  {
    type: 'page',
    label: 'Pathology',
    url: routes.pathologies,
    Icon: FaSyringe,
  },
  {
    type: 'page',
    label: 'Packing Bundles',
    url: routes.packingBundles,
    Icon: FaReceipt,
  },
  {
    type: 'page',
    label: 'Awaiting Scripts',
    url: routes.awaitingScripts,
    Icon: FaFilePrescription,
  },
  {
    type: 'page',
    label: 'Tracking',
    url: routes.tracking,
    Icon: FaWeight,
  },
  {
    type: 'page',
    label: 'Flows',
    url: routes.flows,
    Icon: FaWpforms,
  },
  {
    type: 'page',
    label: 'Practitioners',
    url: routes.clinicians,
    Icon: FaStethoscope,
  },
  {
    type: 'page',
    label: 'Products',
    url: routes.products,
    Icon: FaShoppingBag,
  },
  {
    type: 'page',
    label: 'Plans',
    url: routes.plans,
    Icon: FaRegListAlt,
  },
  {
    type: 'page',
    label: 'Sequences',
    url: routes.sequences,
    Icon: FaPrescriptionBottleAlt,
  },
  {
    type: 'page',
    label: 'Offerings',
    url: routes.offerings,
    Icon: FaShoppingCart,
  },
  {
    type: 'page',
    label: 'Discounts',
    url: routes.legacyDiscounts,
    Icon: FaDollarSign,
  },
  {
    type: 'page',
    label: 'Orders',
    url: routes.orders,
    Icon: FaBoxOpen,
  },
  {
    type: 'page',
    label: 'Scripts',
    url: routes.scripts,
    Icon: FaScroll,
  },
  {
    type: 'page',
    label: 'Subscription Plans',
    url: routes.subscriptionPlans,
    Icon: FaMoneyBillWave,
  },
  {
    type: 'page',
    label: 'Programs',
    url: routes.educationPrograms,
    Icon: FaStream,
  },
  {
    type: 'page',
    label: 'Readings',
    url: routes.educationReadings,
    Icon: FaBook,
  },
  {
    type: 'page',
    label: 'Collections',
    url: routes.educationCollections,
    Icon: FaSchool,
  },
  {
    type: 'page',
    label: 'Content Items',
    url: routes.contentItems,
    Icon: FaVideo,
  },
  {
    type: 'page',
    label: 'Content Collections',
    url: routes.contentCollections,
    Icon: FaPhotoVideo,
  },
];

type SearchResult = {
  type: 'search';
  label: string;
  Icon?: IconType;
  value: string;
  onSelect: () => void;
};

type Result = (Page | SearchResult) & { ref: React.RefObject<HTMLDivElement> };

export function BigBrainBar(): JSX.Element | null {
  const history = useHistory();
  const canViewScripts = useHasPermissions(['VIEW_SCRIPTS', 'EDIT_SCRIPTS']);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const down = (e: KeyboardEvent): void => {
      if (e.key === 'k' && e.metaKey) {
        setShow((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return (): void => document.removeEventListener('keydown', down);
  }, []);

  const [
    search,
    { data: searchResults, loading: searchInProgress, called: searchCalled },
  ] = useLazyQuery<BigSearchQuery, BigSearchQueryVariables>(bigSearchQuery);

  const [userText, setUserText] = useState('');
  useEffect(() => {
    // dont search on load
    if (!userText && !searchCalled) return;
    const skipCid =
      userText.length < 10 || (!isCuid(userText) && validate(userText));
    search({
      variables: {
        id: userText,
        ids: userText,
        skipcid: skipCid,
        skipv4: !(validate(userText) && version(userText) === 4),
        includeSearch: userText.length >= 3,
        userSearch: { email: { contains: userText } },
        skipScript: skipCid || !canViewScripts,
      },
    });
  }, [search, searchCalled, userText, canViewScripts]);

  const [pages] = useState<Page[]>(
    startingPages.map((p) => ({
      label: p.label,
      type: p.type,
      onSelect: (): void => history.push(p.url),
      Icon: p.Icon,
    })),
  );

  type ResultGroup = { title: string; results: Result[] };
  const [resultGroups, setResultGroups] = useState<ResultGroup[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);

  useEffect(() => {
    if (!userText) setSelectedIndex(null);
  }, [userText]);

  const selectPrevious = useCallback(
    () =>
      setSelectedIndex((i) => {
        const total = resultGroups.reduce((p, c) => p + c.results.length, 0);
        return i === null ? total - 1 : i === 0 ? null : i - 1;
      }),
    [resultGroups],
  );
  const selectNext = useCallback(
    () =>
      setSelectedIndex((i) => {
        const total = resultGroups.reduce((p, c) => p + c.results.length, 0);
        return i === null
          ? total > 1 && userText
            ? 1
            : 0
          : i >= total - 1
          ? null
          : i + 1;
      }),
    [resultGroups, userText],
  );

  useEffect(() => {
    const ui = userText.trim().toLowerCase();

    const exactMatchResults: Result[] = [];
    const fuzzySearchResults: Result[] = [];

    if (searchResults?.consultation) {
      const c = searchResults.consultation;
      exactMatchResults.push({
        label: 'Consultation',
        type: 'search',
        value: c.id,
        Icon: FaClipboardList,
        onSelect: () => history.push(buildRoute.consultation(c.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.user) {
      const u = searchResults.user;
      exactMatchResults.push({
        label: 'User',
        type: 'search',
        value: u.id,
        Icon: FaUser,
        onSelect: () =>
          history.push(
            u.role === 'CUSTOMER'
              ? buildRoute.customer(u.id)
              : buildRoute.clinician(u.id),
          ),
        ref: createRef(),
      });
    }
    if (searchResults?.users) {
      for (const u of searchResults.users) {
        fuzzySearchResults.push({
          label: 'User',
          type: 'search',
          value: u.email || u.id,
          Icon: FaUser,
          onSelect: () =>
            history.push(
              u.role === 'CUSTOMER'
                ? buildRoute.customer(u.id)
                : buildRoute.clinician(u.id),
            ),
          ref: createRef(),
        });
      }
    }
    if (searchResults?.pathologyRequest) {
      const p = searchResults.pathologyRequest;
      exactMatchResults.push({
        label: 'Pathology Request',
        type: 'search',
        value: p.id,
        Icon: FaSyringe,
        onSelect: () => history.push(buildRoute.pathology(p.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.product) {
      const p = searchResults.product;
      exactMatchResults.push({
        label: 'Product',
        type: 'search',
        value: p.id,
        Icon: FaShoppingBag,
        onSelect: () => history.push(buildRoute.product(p.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.plan) {
      const p = searchResults.plan;
      exactMatchResults.push({
        label: 'Plan',
        type: 'search',
        value: p.id,
        Icon: FaRegListAlt,
        onSelect: () => history.push(buildRoute.plan(p.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.order) {
      const o = searchResults.order;
      exactMatchResults.push({
        label: 'Order',
        type: 'search',
        value: o.id,
        Icon: FaBoxOpen,
        onSelect: () => history.push(buildRoute.order(o.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.coreOrderByOrderId) {
      const o = searchResults.coreOrderByOrderId;
      exactMatchResults.push({
        label: 'Order (Flexi)',
        type: 'search',
        value: o.id,
        Icon: FaBoxOpen,
        onSelect: () => history.push(buildRoute.coreOrder(o.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.coreOrderByConsignmentId) {
      const o = searchResults.coreOrderByConsignmentId;
      exactMatchResults.push({
        label: 'Order (Flexi)',
        type: 'search',
        value: o.id,
        Icon: FaBoxOpen,
        onSelect: () => history.push(buildRoute.coreOrder(o.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.discountCode) {
      const d = searchResults.discountCode;
      exactMatchResults.push({
        label: 'Discount Code',
        type: 'search',
        value: d.id,
        Icon: FaDollarSign,
        onSelect: () => history.push(buildRoute.discountCode(d.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.script) {
      const s = searchResults.script;
      exactMatchResults.push({
        label: 'Script',
        type: 'search',
        value: s.id,
        Icon: FaScroll,
        onSelect: () => history.push(buildRoute.script(s.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.subscriptionPlanV2ById) {
      const s = searchResults.subscriptionPlanV2ById;
      exactMatchResults.push({
        label: 'Subscription Plan',
        type: 'search',
        value: s.id,
        Icon: FaMoneyBillWave,
        onSelect: () => history.push(buildRoute.subscriptionPlan(s.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.offering) {
      const o = searchResults.offering;
      exactMatchResults.push({
        label: 'Offering',
        type: 'search',
        value: o.id,
        Icon: FaShoppingCart,
        onSelect: () => history.push(buildRoute.offering(o.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.sequence) {
      const s = searchResults.sequence;
      exactMatchResults.push({
        label: 'Sequence',
        type: 'search',
        value: s.id,
        Icon: FaPrescriptionBottleAlt,
        onSelect: () => history.push(buildRoute.sequence(s.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.contentCollection) {
      const c = searchResults.contentCollection;
      exactMatchResults.push({
        label: 'Content Collection',
        type: 'search',
        value: c.id,
        Icon: FaPhotoVideo,
        onSelect: () => history.push(buildRoute.contentCollection(c.id)),
        ref: createRef(),
      });
    }
    if (searchResults?.purchase) {
      const p = searchResults.purchase;
      exactMatchResults.push({
        label: 'Purchase',
        type: 'search',
        value: p.id,
        Icon: FaCartPlus,
        onSelect: () =>
          history.push(
            buildRoute.customerPurchase(p.customer?.id ?? '', p.id ?? ''),
          ),
        ref: createRef(),
      });
    }
    if (searchResults?.purchaseSyncGroup) {
      const s = searchResults.purchaseSyncGroup;
      const p = s.sequenceContexts?.[0]?.purchase;
      exactMatchResults.push({
        label: 'Purchase Sync Group',
        type: 'search',
        value: s.id,
        Icon: FaProjectDiagram,
        onSelect: () =>
          history.push(
            buildRoute.customerPurchase(p?.customer?.id ?? '', p?.id ?? ''),
          ),
        ref: createRef(),
      });
    }
    if (searchResults?.sequenceContext) {
      const s = searchResults.sequenceContext;
      exactMatchResults.push({
        label: 'Sequence Context',
        type: 'search',
        value: s.id,
        Icon: FaProjectDiagram,
        onSelect: () =>
          history.push(
            buildRoute.sequenceContext(
              s.purchase?.customer?.id ?? '',
              s.purchase?.id ?? '',
              s.id ?? '',
            ),
          ),
        ref: createRef(),
      });
    }

    const availablePages = pages.filter((c) =>
      c.label.toLowerCase().includes(ui),
    );

    const groups: ResultGroup[] = [];

    if (exactMatchResults.length)
      groups.push({ title: 'Resource', results: exactMatchResults });

    if (availablePages.length)
      groups.push({
        title: 'Pages',
        results: availablePages.map((p) => ({ ...p, ref: createRef() })),
      });

    if (fuzzySearchResults.length)
      groups.push({ title: 'Search Results', results: fuzzySearchResults });

    setResultGroups(groups);
  }, [history, pages, searchResults, userText]);

  const inputRef = useRef<HTMLInputElement>(null);
  const resultsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    resultsListRef.current?.scrollTo(0, 0);
  }, [userText]);

  useEffect(() => {
    if (selectedIndex === null) {
      inputRef.current?.focus();
    } else {
      resultGroups
        .flatMap((rg) => rg.results)
        [selectedIndex]?.ref.current?.focus();
    }
  }, [resultGroups, selectedIndex]);

  const closeWithoutClear = useCallback(() => setShow(false), []);
  const closeWithClear = useCallback(() => {
    setShow(false);
    setUserText('');
    setSelectedIndex(null);
  }, []);

  if (!show) {
    return (
      <div
        id="show-big-brain"
        className="hidden"
        onClick={(): void => setShow(true)}
      />
    );
  }

  return (
    <Modal show={show} onClose={closeWithoutClear}>
      <div
        className="bg-white rounded-md shadow-md border border-gray-500 divide-y divide-gray-400"
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            if (
              selectedIndex === null &&
              resultGroups.flatMap((rg) => rg.results).length
            ) {
              resultGroups[0].results[0].onSelect();
              closeWithClear();
            } else if (selectedIndex !== null) {
              resultGroups
                .flatMap((rg) => rg.results)
                [selectedIndex].onSelect();
              closeWithClear();
            }
          } else if (e.key === 'Tab') {
            e.stopPropagation();
            e.preventDefault();
            if (e.shiftKey) {
              selectPrevious();
            } else {
              selectNext();
            }
          } else if (e.key === 'ArrowUp' || (e.key === 'k' && e.ctrlKey)) {
            e.stopPropagation();
            e.preventDefault();
            selectPrevious();
          } else if (e.key === 'ArrowDown' || (e.key === 'j' && e.ctrlKey)) {
            e.stopPropagation();
            e.preventDefault();
            selectNext();
          }
        }}
      >
        <div className="px-4 py-2 flex items-center space-x-2">
          {searchInProgress ? (
            <FaSpinner className="opacity-60 animate-spin" />
          ) : (
            <FaSearch className="opacity-60" />
          )}

          <input
            ref={inputRef}
            tabIndex={1}
            autoFocus
            className="py-2 w-full placeholder-gray-600 focus:outline-none"
            placeholder="Search for pages or ids..."
            value={userText}
            onChange={(e): void => setUserText(e.target.value)}
            spellCheck="false"
            onFocus={(): void => setSelectedIndex(null)}
          />
        </div>
        <div
          className="pb-2 space-y-1 max-h-96 overflow-y-auto divide-y divide-gray-400"
          ref={resultsListRef}
        >
          {!userText.length && (
            <div
              className="text-right opacity-60 p-1 pt-2 pr-4"
              style={{ fontSize: '0.75em' }}
            >
              Type{' '}
              <span className="border border-gray-500 rounded bg-gray-200 px-1">
                ?
              </span>{' '}
              for help and tips
            </div>
          )}

          {userText === '?' ? (
            <div className="px-4 pt-6 pb-4 space-y-6 text-gray-800 text-center">
              <div>
                ⚡️ Launch this search with{' '}
                <span className="border border-gray-500 rounded bg-gray-200 px-1">
                  CMD
                </span>
                {' + '}
                <span className="border border-gray-500 rounded bg-gray-200 px-1">
                  K
                </span>
              </div>
              <div>
                😍 Select the first result by pressing{' '}
                <span className="border border-gray-500 rounded bg-gray-200 px-1">
                  Enter
                </span>
              </div>
              <div>🤯 Paste any ID to search for it!</div>
            </div>
          ) : resultGroups.length ? (
            resultGroups.map((rg, rgi) => {
              const selectIdxOffset = resultGroups
                .slice(0, rgi)
                .reduce((p, c) => p + c.results.length, 0);
              return (
                <div key={rg.title} className="pt-3 px-2">
                  <div className="py-2 px-2 text-sm leading-3 opacity-80">
                    {rg.title}
                  </div>
                  {rg.results.length ? (
                    rg.results.map((c, i) => (
                      <div
                        key={i}
                        className={
                          'py-2 px-2 rounded-md flex justify-between items-center cursor-pointer hover:bg-gray-200 focus:bg-gray-200 ' +
                          (selectedIndex === null &&
                          userText.length > 0 &&
                          rgi === 0 &&
                          i === 0
                            ? 'bg-gray-200'
                            : '')
                        }
                        ref={c.ref}
                        onMouseEnter={(): void =>
                          setSelectedIndex(selectIdxOffset + i)
                        }
                        tabIndex={2 + selectIdxOffset + i}
                        onFocus={(): void =>
                          setSelectedIndex(selectIdxOffset + i)
                        }
                        onClick={(): void => {
                          c.onSelect();
                          closeWithClear();
                        }}
                        onKeyDown={(e): void => {
                          if (
                            e.key !== 'Enter' &&
                            e.key !== 'Tab' &&
                            e.key !== 'Escape' &&
                            e.key !== 'ArrowUp' &&
                            e.key !== 'ArrowDown' &&
                            !(e.key === 'j' && e.ctrlKey) &&
                            !(e.key === 'k' && e.ctrlKey)
                          ) {
                            setSelectedIndex(null);
                          }
                        }}
                      >
                        <span className="" style={{ maxWidth: '95%' }}>
                          {c.type === 'page' ? (
                            <div className="flex items-center">
                              {c.Icon && (
                                <c.Icon
                                  className="mr-2 opacity-60"
                                  style={{ paddingBottom: 0 }}
                                />
                              )}
                              <SmokingPipe
                                text={c.label}
                                highlight={userText}
                              />
                            </div>
                          ) : c.type === 'search' ? (
                            <div className="flex items-center">
                              {c.Icon && (
                                <c.Icon
                                  className="mr-2 opacity-60"
                                  style={{ paddingBottom: 0 }}
                                />
                              )}
                              <span className="capitalize whitespace-nowrap">
                                {c.label}
                              </span>
                              <span className="mx-2 opacity-40 text-sm">
                                {'>'}
                              </span>
                              <span className="opacity-60 truncate inline-block">
                                <SmokingPipe
                                  text={c.value}
                                  highlight={userText}
                                />
                              </span>
                            </div>
                          ) : null}
                        </span>
                        <span className="ml-4 opacity-60 capitalize text-sm">
                          {/* {c.type} */}
                        </span>
                      </div>
                    ))
                  ) : searchInProgress ? (
                    <div className="p-2 px-2 opacity-80">Searching...</div>
                  ) : (
                    <div className="p-2 px-2 opacity-80">No results</div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="p-2 pt-4 px-4 opacity-80">No results</div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const bigSearchQuery = gql`
  query BigSearch(
    $id: ID!
    $ids: String!
    $skipcid: Boolean!
    $skipv4: Boolean!
    $includeSearch: Boolean!
    $userSearch: UserWhereInput
    $skipScript: Boolean!
  ) {
    consultation(where: { id: $ids }) @skip(if: $skipcid) {
      id
    }
    user(where: { id: $ids }) @skip(if: $skipcid) {
      id
      role
    }
    users(where: $userSearch, take: 30) @include(if: $includeSearch) {
      id
      role
      email
    }
    pathologyRequest(id: $ids) @skip(if: $skipv4) {
      id
    }
    product(where: { id: $ids }) @skip(if: $skipcid) {
      id
    }
    plan(where: { id: $ids }) @skip(if: $skipcid) {
      id
    }
    order(where: { id: $ids }) @skip(if: $skipcid) {
      id
    }
    discountCode(where: { id: $ids }) @skip(if: $skipcid) {
      id
    }
    script(where: { id: $ids }) @skip(if: $skipScript) {
      id
    }
    subscriptionPlanV2ById(id: $ids) @skip(if: $skipcid) {
      id
    }
    offering(id: $id) @skip(if: $skipv4) {
      id
    }
    sequence(id: $id) @skip(if: $skipv4) {
      id
    }
    contentCollection(id: $id) @skip(if: $skipv4) {
      id
    }
    coreOrderByOrderId: coreOrder(id: $id) @skip(if: $skipv4) {
      id
    }
    coreOrderByConsignmentId: coreOrder(consignmentId: $id) @skip(if: $skipv4) {
      id
    }
    purchase(id: $id) @skip(if: $skipv4) {
      id
      customer {
        id
      }
    }
    sequenceContext(id: $id) @skip(if: $skipv4) {
      id
      purchase {
        id
        customer {
          id
        }
      }
    }
    purchaseSyncGroup(id: $id) @skip(if: $skipv4) {
      id
      sequenceContexts {
        id
        purchase {
          id
          customer {
            id
          }
        }
      }
    }
  }
`;

function SmokingPipe({
  text,
  highlight,
}: {
  text: string;
  highlight: string;
}): JSX.Element {
  const [before, bold, after] = useMemo(() => {
    const search = highlight.trim().toLocaleLowerCase();
    const pos = text.toLowerCase().indexOf(search);

    if (pos === -1) {
      return [text, '', ''];
    }

    return [
      text.slice(0, pos),
      text.slice(pos, pos + search.length),
      text.slice(pos + search.length),
    ];
  }, [text, highlight]);
  return (
    <>
      <span>{before}</span>
      <span className="font-bold">{bold}</span>
      <span>{after}</span>
    </>
  );
}

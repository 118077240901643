import clsx from 'clsx';
import { Checkbox } from 'components/checkbox';
import React, { useMemo } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import {
  FaArrowDown,
  FaArrowUp,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Cell,
  CellProps,
  Column,
  ColumnWithStrictAccessor,
  Hooks,
  Row,
  TableInstance,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useUpdateEffect } from 'react-use';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';

export type ColumnTypes<T extends Record<string, unknown>> = Array<
  ColumnWithStrictAccessor<T>
>;

const buttonSpacing = 'w-10 h-10 ml-2 first:ml-0 rounded';

const pagerStyle = (canPage: boolean): string =>
  clsx(
    buttonSpacing,
    'border',
    { 'text-gray-600 border-gray-600 hover:bg-gray-400': canPage },
    { 'text-gray-500 border-gray-500 cursor-not-allowed': !canPage },
  );

interface TableInterface<D extends Record<string, unknown>> {
  tableInstance: TableInstance<D> | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableContext = React.createContext<TableInterface<any>>({
  tableInstance: null,
});

const useTableContext = <
  D extends Record<string, unknown>,
>(): TableInterface<D> => {
  return React.useContext(TableContext);
};

export const Table = <D extends Record<string, unknown>>({
  tableInstance,
  children,
}: {
  tableInstance: TableInstance<D>;
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => {
  const [instance, setInstance] = React.useState(tableInstance);

  React.useEffect(() => {
    setInstance(instance);
  }, [instance]);

  return (
    <TableContext.Provider
      value={{
        tableInstance: instance,
      }}
    >
      <table
        className="w-full h-full text-sm relative"
        {...tableInstance.getTableProps()}
      >
        {children}
      </table>
    </TableContext.Provider>
  );
};

export const TableBody = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => {
  const { tableInstance } = useTableContext();
  return <tbody {...tableInstance?.getTableBodyProps()}>{children}</tbody>;
};

export const TableCell = <D extends Record<string, unknown>>({
  cell,
  onClick,
  className,
  userProps,
}: {
  cell: Cell<D, unknown>;
  onClick?(cell: Cell<D, unknown>): void;
  className?: string;
  userProps?: Record<string, unknown>;
}): React.ReactElement => {
  return (
    <td
      {...cell.getCellProps()}
      className={clsx(className || 'px-4 h-16')}
      key={cell.column.id}
      onClick={(): void => {
        onClick?.(cell);
      }}
    >
      {cell.column.link ? (
        <Link
          className="underline"
          to={cell.column.link(cell.row.original)}
          onClick={(e) => e.stopPropagation()}
        >
          {cell.render('Cell', userProps)}
        </Link>
      ) : (
        cell.render('Cell', userProps)
      )}
    </td>
  );
};

export const TableHead = (): React.ReactElement => {
  const { tableInstance } = useTableContext();
  return (
    <thead>
      {tableInstance?.headerGroups?.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          key={headerGroup.getHeaderGroupProps().key ?? headerGroup.id}
        >
          {headerGroup.headers.map((column) => (
            <th
              {...column.getHeaderProps(column?.getSortByToggleProps?.())}
              className={clsx(
                'heading-sm',
                'text-left',
                'select-none',
                'pb-3',
                'px-3',
                'border-b-4',
                'border-transparent',
                {
                  'border-gray-700': column.isSorted,
                },
                column.className,
              )}
              key={column.id}
            >
              {column.render('Header')}
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <FaArrowDown className="float-right" />
                ) : (
                  <FaArrowUp className="float-right" />
                )
              ) : null}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export const TableRow = <D extends Record<string, unknown>>({
  row,
  children,
  underline,
  isClickable = true,
  isDragging,
  draggableProvided,
}: {
  row: Row<D>;
  children: React.ReactElement | React.ReactElement[];
  underline?: boolean;
  isClickable?: boolean;
  isDragging?: boolean;
  draggableProvided?: DraggableProvided;
}): React.ReactElement => {
  return (
    <React.Fragment key={row.id}>
      {!isDragging && (
        <tr className="h-2 bg-transparent">
          {/* Spacer to allow for shadow */}
        </tr>
      )}
      <tr
        ref={draggableProvided?.innerRef}
        {...draggableProvided?.draggableProps}
        {...row.getRowProps()}
        className={clsx(
          'border',
          'shadow-card',
          'mt-2',
          {
            'border-b-2 border-primary': underline,
            'cursor-pointer hover:bg-hover': isClickable,
          },
          row.isSelected
            ? 'bg-secondary-100 border-primary-darker'
            : 'bg-white',
        )}
      >
        {children}
      </tr>
    </React.Fragment>
  );
};

export const Pagination = <D extends Record<string, unknown>>({
  tableInstance,
  total,
}: {
  tableInstance: TableInstance<D>;
  total: number | string;
}): React.ReactElement => {
  const {
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex },
  } = tableInstance;
  const changeUrl = useChangeUrl();
  useUpdateEffect(() => {
    changeUrl({
      params: {
        pageIndex,
      },
    });
  }, [pageIndex]);

  return (
    <div className="w-full flex justify-end pt-2">
      <span className="p-2">{total}</span>
      {[...new Array(pageCount)].map((_, i) => {
        const numberOfButtonsBeforeClipping = 3;
        if (
          i < numberOfButtonsBeforeClipping ||
          i === pageIndex ||
          i === pageCount - 1
        ) {
          return (
            <button
              key={i}
              className={clsx(
                buttonSpacing,
                {
                  'text-gray-600 border-gray-600 hover:bg-gray-400 border':
                    i !== pageIndex,
                },
                {
                  'text-gray-700 border-gray-700 border-2': i === pageIndex,
                },
              )}
              onClick={(): void => gotoPage(i)}
            >
              {i + 1}
            </button>
          );
        } else if (i === pageIndex + 1 || i === numberOfButtonsBeforeClipping) {
          return (
            <p
              key={i}
              className={clsx(
                buttonSpacing,
                'tracking-widest text-center leading-7',
              )}
            >
              ...
            </p>
          );
        }

        return null;
      })}
      <button
        className={pagerStyle(canPreviousPage)}
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <FaChevronLeft className="mx-auto stroke-current" />
      </button>
      <button
        className={pagerStyle(canNextPage)}
        onClick={nextPage}
        disabled={!canNextPage}
      >
        <FaChevronRight className="mx-auto stroke-current" />
      </button>
    </div>
  );
};

export const SimplePagination = ({
  pageIndex,
  canNextPage = true,
}: {
  pageIndex: number;
  canNextPage?: boolean;
}): React.ReactElement => {
  const changeUrl = useChangeUrl();

  const updatePageIndex = (newIndex: number): void =>
    changeUrl({
      params: {
        pageIndex: newIndex,
      },
    });

  const next = (): void => updatePageIndex(pageIndex + 1);
  const prev = (): void => updatePageIndex(pageIndex - 1);
  const canPrevPage = pageIndex > 0;
  return (
    <div className="w-full flex justify-end pt-2">
      <button
        className={pagerStyle(canPrevPage)}
        onClick={prev}
        disabled={!canPrevPage}
      >
        <FaChevronLeft className="mx-auto stroke-current" />
      </button>
      <span className="p-2 ml-2">Page {pageIndex + 1}</span>
      <button
        className={pagerStyle(canNextPage)}
        onClick={next}
        disabled={!canNextPage}
      >
        <FaChevronRight className="mx-auto stroke-current" />
      </button>
    </div>
  );
};

const SelectRowCell = ({
  name,
  indeterminate,
  onChange,
  checked,
}: {
  name: string;
  indeterminate?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  checked: boolean;
}): React.ReactElement => {
  const ref = React.useRef<HTMLInputElement>(null);

  if (ref.current) {
    ref.current.checked = checked;
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = indeterminate;
    }
  }

  return (
    <Checkbox
      ref={ref}
      label={''}
      name={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange?.(e);
      }}
    />
  );
};

const useSelectColumn =
  (disableSelectAll: boolean) =>
  <D extends Record<string, unknown>>(hooks: Hooks<D>): void => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        className: 'w-24',
        Header: (h) => {
          if (disableSelectAll) {
            return null;
          }

          const { indeterminate, onChange, checked } =
            h.getToggleAllRowsSelectedProps();

          return (
            <SelectRowCell
              indeterminate={indeterminate}
              onChange={onChange}
              checked={!!checked}
              name={'selectAll'}
            />
          );
        },
        Cell: (r: CellProps<D>) => {
          const { indeterminate, onChange, checked } =
            r.row.getToggleRowSelectedProps();

          return (
            <SelectRowCell
              indeterminate={indeterminate}
              onChange={onChange}
              checked={!!checked}
              name={r.id}
            />
          );
        },
      },
      ...columns,
    ]);
  };

export const useSelectableTable = <D extends Record<string, unknown>>({
  columns,
  data,
  disableSelectAll = false,
}: {
  columns: Column<D>[];
  data: D[];
  disableSelectAll?: boolean;
}): TableInstance<D> => {
  const values = useTable<D>(
    {
      autoResetPage: true,
      manualPagination: true,
      manualSortBy: true,
      columns: columns,
      data: data,
    },
    useRowSelect,
    useSelectColumn(disableSelectAll),
  );
  return values;
};

export const useSelectablePaginatingSortingTable = <
  D extends Record<string, unknown>,
>({
  columns,
  data,
  pageNumber,
  orderBy = [],
  pageIndex = 0,
  disableSelectAll = false,
}: {
  columns: Column<D>[];
  data: D[];
  pageNumber: number;
  orderBy?: Record<string, 'asc' | 'desc'>[];
  pageIndex?: number;
  disableSelectAll?: boolean;
}): TableInstance<D> => {
  const values = useTable<D>(
    {
      initialState: {
        pageIndex,
        sortBy: orderBy.map((it) => {
          const [first] = Object.entries(it);
          return {
            id: first[0],
            desc: first[1] === 'desc',
          };
        }),
      },
      pageCount: pageNumber,
      autoResetPage: true,
      manualPagination: true,
      manualSortBy: true,
      columns: columns,
      data: data,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn(disableSelectAll),
  );
  const {
    state: { sortBy },
  } = values;
  const changeUrl = useChangeUrl();
  useUpdateEffect(() => {
    if (sortBy && sortBy.length > 0) {
      changeUrl({
        params: {
          orderBy: `${sortBy[0].id}_${sortBy[0].desc ? 'desc' : 'asc'}`,
        },
      });
    }
  }, [sortBy]);
  return values;
};

export const usePaginatingSortingTable = <D extends Record<string, unknown>>({
  columns,
  data,
  pageNumber,
  orderBy = [],
  pageIndex = 0,
  pageSize,
  disableSortBy,
}: {
  columns: Column<D>[];
  data: D[];
  pageNumber: number;
  orderBy?: Record<string, 'asc' | 'desc'>[];
  pageIndex?: number;
  pageSize?: number;
  disableSortBy?: boolean;
}): TableInstance<D> => {
  const values = useTable<D>(
    {
      initialState: {
        pageIndex,
        sortBy: orderBy.map((it) => {
          const [first] = Object.entries(it);
          return {
            id: first[0],
            desc: first[1] === 'desc',
          };
        }),
        pageSize,
      },
      pageSize: pageSize,
      pageCount: pageNumber,
      autoResetPage: true,
      manualPagination: true,
      manualSortBy: true,
      columns: columns,
      data: data,
      disableSortBy,
    },
    useSortBy,
    usePagination,
  );
  const {
    state: { sortBy },
  } = values;
  const changeUrl = useChangeUrl();
  useUpdateEffect(() => {
    if (sortBy && sortBy.length > 0) {
      changeUrl({
        params: {
          orderBy: `${sortBy[0].id}_${sortBy[0].desc ? 'desc' : 'asc'}`,
        },
      });
    }
  }, [sortBy]);
  return values;
};

export const usePageIndex = (): number => {
  const query = useUrlQuery();
  return useMemo(() => parseInt(query.get('pageIndex') ?? '0', 10), [query]);
};

export type UseOrderByParams = {
  defaultValue: string;
};

const EMPTY_RECORD = {};

export const useOrderBy = ({
  defaultValue,
}: UseOrderByParams): Record<string, 'asc' | 'desc'>[] => {
  const query = useUrlQuery();
  const value = query.get('orderBy') ?? defaultValue;
  let result = EMPTY_RECORD;
  if (value) {
    const [key, order] = value.split('_');
    result = {
      ...result,
      [key]: order?.toLocaleLowerCase() === 'asc' ? 'asc' : 'desc',
    };
  }

  return useMemo(() => [result], [result]);
};

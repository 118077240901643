import { defaultJuniper } from './default-juniper-uk';
import { sharedProdEuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultJuniper,
  ...sharedProdEuEnv,
  environment: 'production',
  apiUrl: 'https://api.admin.eu.eucalyptus.vc',
  restApiUrl: 'https://admin-api.myjuniper.co.uk',
  assetsUrl: 'https://assets.myjuniper.co.uk',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
  shopifyHostname: 'myjuniper-uk.myshopify.com',
  userAppUrl: 'https://app.myjuniper.co.uk',
  doctorsAppUrl: 'https://prescribers.myjuniper.co.uk',
  googleOAuthClientId:
    '966685249037-gin9l5mhlahpk6eud426k1fu32ksnvm5.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.myjuniper.co.uk',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
  healthCoachingFlows: true,
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Access": [
      "TimedAccess"
    ],
    "AddressHistoryEntry": [
      "ResidentialAddressHistoryEntry",
      "ShippingAddressHistoryEntry"
    ],
    "ColdShippingProvider": [
      "AuColdShippingProvider"
    ],
    "ContentItem": [
      "FaqContentItem",
      "ReadingContentItem",
      "RecipeContentItem",
      "VideoContentItem",
      "VideoWithReadingContentItem"
    ],
    "CouponCampaignDiscountAmount": [
      "CouponCampaignFixedDiscountAmount",
      "CouponCampaignPercentageDiscountAmount"
    ],
    "CouponCampaignTemplateParameter": [
      "CouponCampaignBooleanTemplateParameter",
      "CouponCampaignNumberTemplateParameter",
      "CouponCampaignPercentageTemplateParameter",
      "CouponCampaignSkuListTemplateParameter",
      "CouponCampaignStringArrayTemplateParameter",
      "CouponCampaignStringTemplateParameter",
      "CouponCampaignTimestampTemplateParameter"
    ],
    "CreateReviewConsultationFilter": [
      "CreateReviewConsultationFilterByCustomers",
      "CreateReviewConsultationFilterByProperties"
    ],
    "FulfillmentVendorDetails": [
      "EvermedFulfillmentVendorDetails"
    ],
    "HealthCoachingFlowNodeTemplate": [
      "HealthCoachingFlowMessageNodeTemplate",
      "HealthCoachingFlowWaitNodeTemplate"
    ],
    "LevelTreeGoalTemplate": [
      "DefaultLevelTreeGoalTemplate",
      "VariantLevelTreeGoalTemplate"
    ],
    "Note": [
      "ConsultationNote",
      "CustomerNote",
      "MetaNote"
    ],
    "Offering": [
      "AddOnOffering",
      "OtcOffering",
      "RxOffering"
    ],
    "OrderShippingDetails": [
      "AponsOrderShippingDetails",
      "EvermedOrderShippingDetails",
      "PluripharmOrderShippingDetails",
      "ShopifyOrderShippingDetails"
    ],
    "PaginationPayload": [
      "CouponCampaignSummariesPayload",
      "PractitionersPayload"
    ],
    "PausePurchaseFilter": [
      "PausePurchaseFilterByCustomers",
      "PausePurchaseFilterByProperties"
    ],
    "PhoneCallProviderDetails": [
      "LinePhoneCallDetails",
      "ZoomPhoneCallDetails"
    ],
    "PurchasePrompt": [
      "ConfirmPurchasePrompt",
      "FurPurchasePrompt",
      "InitialPurchasePrompt",
      "RestartFlowPrompt",
      "SubstitutePurchasePrompt"
    ],
    "PurchaseSyncGroupAction": [
      "AdvancePurchaseSyncGroupAction",
      "DelayPurchaseSyncGroupAction",
      "ExpeditePurchaseSyncGroupAction",
      "RepeatPurchaseSyncGroupForFreeOrderAction",
      "ResumePurchaseSyncGroupAction"
    ],
    "QuizQuestionSubmitOutcomes": [
      "QuizQuestionSubmitAnyOutcomes",
      "QuizQuestionSubmitSomeOutcomes"
    ],
    "ReorderLevelTreeGoalTemplatesPayloadParentType": [
      "DefaultLevelTreeGoalTemplate",
      "LevelTreeLevelTemplate"
    ],
    "SequenceNode": [
      "EventSequenceNode",
      "InternalTriggerSequenceNode",
      "SmsSequenceNode",
      "VariantSequenceNode",
      "WaitSequenceNode"
    ],
    "SequenceNodeParentType": [
      "Sequence",
      "SequenceContextOverride"
    ],
    "ShopSection": [
      "ItemListShopSection"
    ],
    "StockoutToolingBatch": [
      "CreateReviewConsultationStockoutToolingBatch",
      "PausePurchaseStockoutToolingBatch"
    ]
  }
};
      export default result;
    